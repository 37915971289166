import { Person } from "@mui/icons-material";
import {
  Security
} from "@mui/icons-material";
import React from "react";
import ColorChip from "./ColorChip";

const roleChip = (roleLabel) => {
  switch (roleLabel) {
    case 0:
      return (
        <ColorChip
          color="primary"
          variant="outlined"
          size="small"
          label="Admin"
          icon={<Security />}
        />
      );
    case 1:
      return (
        <ColorChip
          color="secondary"
          variant="outlined"
          size="small"
          label="User"
          icon={<Person />}
        />
      );
    default:
      return roleLabel;
  }
};

export { roleChip };
