import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { getRole, getToken, removeUserSession} from './Utils/Common';
import { DataGrid } from '@mui/x-data-grid';
import HandleRejection from './components/RoleRejection';

function Sessions(props) {
  const { params } = props.match;
  console.log(params)
  const role = getRole();
  const unit_id = params.unit_id;
  const session_type = params.session_type;
  const start_date = params.start_date;
  const end_date = params.end_date;
  const assignment = params.assignment;
  console.log(assignment)
  const token = getToken();
  const [ sessions, setSessions ] = useState([]);
  const [ count, setCount ] = useState(0);
  const [ page, setPage ] = useState(0);

  const config = {
    headers: { Authorization: `Bearer ${token}` }
   };

  // get events on init render
  useEffect(() => {
    getSessionsList();
  }, [] );

  const getSessionsList=async(next_page)=>{
    try {
        if(!next_page) {
          next_page = 1;
        }
        else if (next_page >= 1) {
          next_page = next_page + 1
        }
        var url;
        if(start_date !== 'none' && end_date !== 'none') { 
          url = '/api/v1/' + session_type +'/' + unit_id + '/sessions?start_date=' + start_date + '&end_date=' + end_date + '&page=' + next_page;
        }
        else {
          url = '/api/v1/' + session_type +'/' + unit_id + '/sessions?page=' + next_page;
        }
        console.log('sessionsurl:',url);
        const response=await axios.get(url, config).catch(e => {
          console.log('error');
          console.log(e);
          if (e.response.status !== 404) {
            removeUserSession();
            window.location.reload();
          }
        });
        console.log(response);
        var myJson = JSON.stringify(response.data);
        console.log("events:", myJson);
        if (response.status === 404 || response.data.count === 0){
          setSessions([]);
          setCount(0);
        } else {
          console.log(response.data.sessions)
          setSessions(response.data.sessions);
          setCount(response.data.count);
        }
    } catch(error) {
        console.log(error.response);
        setSessions([]);
        setCount(0);
    }
  }

  const sessionColumns = [
    { field: 'treatment_id', headerName: 'ID', width: 100 },
    {
      field: 'start_date',
      headerName: 'Start Treatment',
      width: 200,
      sortable: false,
      editable: false,
    },
    {
      field: 'end_date',
      headerName: 'End Treatment',
      width: 200,
      sortable: false,
      editable: false,
    },
    {
      field: ('session_time'),
      headerName: 'Treatment Duration (m)',
      width: 200,
      sortable: false,
      editable: false,
      valueFormatter: (params) => {
        const valueFormatted = Math.round(params.value/60);
        return `${valueFormatted}`;
      }
    },
    {
      field: 'min_vstim',
      headerName: 'Min Intensity',
      width: 140,
      sortable: false,
      editable: false,
    },
    {
      field: 'max_vstim',
      headerName: 'Max Intensity',
      width: 140,
      sortable: false,
      editable: false,
    },
    {
      field: 'min_astim',
      headerName: 'Min Volume',
      width: 140,
      sortable: false,
      editable: false,
    },
    {
      field: 'max_astim',
      headerName: 'Max Volume',
      width: 140,
      sortable: false,
      editable: false,
    },
  ];

  if(role !== "6") {
    return (
      <div>
        <div style={{width: "100%"}}>
          Sessions for {session_type} {unit_id} {assignment !== 'none' && ('on device ' + assignment)} - Total Sessions: {count}
          {count > 0 && (  
            <div style={{background:"white"}}>
              <DataGrid
                color="secondary"
                rows={sessions}
                columns={sessionColumns}
                autoHeight={true}
                loading={sessions.length === 0}
                rowHeight={38}
                rowCount={count}
                checkboxSelection
                disableSelectionOnClick
                pagination
                paginationMode="server"
                onPaginationModelChange={(newPage) => {setPage(newPage.page); getSessionsList(newPage.page);} }
                paginationModel={{page:page, pageSize:20}}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
  else {
    return(
      <HandleRejection open={true}/>
    );
  }
}

export default Sessions;
