import { Button } from '@mui/material';
import { default as React, useState } from 'react';
import { removeUserSession, getToken } from '../Utils/Common';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link } from 'react-router-dom';

const customHeaders = {
  'Content-type': 'application/json',
};

function Logout(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // handle button click of login form
  const handleLogout = (evt) => {
    evt.preventDefault();
    console.log('in handleLogout');
    setError(null);
    setLoading(true);
    removeUserSession();
    window.location.reload();
    }
  

  return (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={(e)=> handleLogout(e)} disabled={loading}
              >
                Logout
              </Button>
              
  );
}
export default Logout;
