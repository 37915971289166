import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import {
  Button,
  Grid,
} from "@mui/material";
import { getToken, getRole, setEditData, removeUserSession } from './Utils/Common';
import { roleChip } from './Utils';
import HandleRejection from './components/RoleRejection';
import EditIcon from '@mui/icons-material/Edit';

function Users(props) {
  const role = getRole();
  const token = getToken();
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  // get devices on init render
  useEffect(() => {
    if (role !== "2" && role !== "3") {
      getUserList();
    }
  }, []);

  const getUserList = async (next_page) => {
    try {
      if (!next_page) {
        next_page = 1;
      }
      else if (next_page >= 1) {
        next_page = next_page + 1
      }
      const url = '/api/v1/users?page=' + next_page;
      console.log('usersurl:', url);
      const response = await axios.get(url, config).catch(e => {
        console.log('error');
        console.log(e);
        if (e.response.status !== 404) {
          removeUserSession();
          window.location.reload();
        }
      });
      console.log(response);
      var myJson = JSON.stringify(response.data);
      console.log("users:", myJson);
      setUsers(response.data.users);
      setCount(response.data.count);
    } catch (error) {
      console.log(error.response);
    }
  }

  function getEditUserLink(id) {
    //const editUrl='/user/' + id + '?user=' + user + '&role=' + role;
    const editUrl = '/user/' + id;
    return editUrl;
  }

  const columns = [
    //{ field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'username',
      headerName: 'Username',
      width: 360,
      editable: false,
    },
    {
      field: 'fname',
      headerName: 'First',
      width: 120,
      editable: false,
    },
    {
      field: 'lname',
      headerName: 'Last',
      width: 120,
      editable: false,
    },
    {
      field: 'privilege',
      headerName: 'Role',
      width: 100,
      sortable: false,
      editable: false,
      renderCell: (params) => roleChip(params.row.privilege),
    },
    {
      field: 'Edit',
      headerName: '',
      width: 150,
      sortable: false,
      editable: false,
      renderCell: (params) =>
        <Button
          onClick={
            () => setEditData(params.row.username, params.row.role)} to={getEditUserLink(params.row.id)
            }
          color="secondary"
        >
          {(role === '0') ? <EditIcon /> : ''}
        </Button>,
    }
  ];

  console.log(users);

  if (role === "0") {
    return (
      <div style={{ width: "100%" }}>
        Users (Total: {count} ):
        {count > 0 && (
          <div style={{ background: "white" }}>
            <br />
            <DataGrid
              color="secondary"
              rows={users}
              columns={columns}
              autoHeight={true}
              loading={users.length === 0}
              rowHeight={38}
              rowCount={count}
              //checkboxSelection
              disableSelectionOnClick
              pagination
              paginationMode="server"
              pageSize={20}
              page={page}
              onPaginationModelChange={(newPage) => { setPage(newPage.page); getUserList(newPage.page); }}
              paginationModel={{ page: page, pageSize: 20 }}
            />
            <Grid container direction="column" alignItems="center" width="100%">
              <Grid item width="100%"></Grid>
            </Grid>
            <div>
              {/* <Link to='/user'>{(role==='0')?'Create User':''}</Link> */}
            </div>
          </div>
        )}
      </div>
    );
  }
  else {
    return (
      <HandleRejection open={true} />
    );
  }
}

export default Users;
