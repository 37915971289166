import React, { useState, useEffect } from 'react';
import { Typography, Button, Grid } from '@mui/material';
import { Link } from "react-router-dom";
import LinearWithLabel from './components/LinearBar';
import { PieChart, Pie, Cell } from 'recharts'
import { getToken, getRole } from './Utils/Common';
import axios from 'axios';
import HandleRejection from './components/RoleRejection';

const token = getToken();
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FB2522', "#6D1C1C", "#F99EF5", "#C514BD"];
const RADIAN = Math.PI / 180;

function Home() {
  const COG_DARK = "#007898";

  const [gs120Count, setgs120Count] = useState(0);
  const [watchCount, setwatchCount] = useState(0);
  const [ipadDeviceCount, setIpadDeviceCount] = useState(0);
  const [treatmentCount, setTreatmentCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [pausedCount, setPausedCount] = useState(0);
  const [flyerCount, setFlyerCount] = useState(0);
  const [mainCount, setMainCount] = useState(0);

  const [siteCount, setSiteCount] = useState(0);
  const [participantCount, setParticipantCount] = useState(0);
  const [studyCount, setStudyCount] = useState(0);
  const [treatmentTime, setTreatmentTime] = useState(0)
  const [sessionCount, setSessionCount] = useState(0)
  const [msgCount, setMsgCount] = useState(0)

  const role = getRole();
  const gsManagerDownloadLink = process.env.REACT_APP_GSMANAGER_URL;

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  useEffect(() => {
    getSummary();
  }, [siteCount]);

  const getSummary = async () => {
    try {
      const summaryUrl = '/api/v1/summary';
      console.log('patientssurl:', summaryUrl);
      const response = await axios.get(summaryUrl, config).catch(e => {
        console.log('error');
        console.log(e);
      });
      console.log(response);

      let totalParticipants = 0;
      let key;
      let participantsObj = response.data.participants;
      //get the total number of participants by adding all the different categories of participants
      for (key in participantsObj) {
        console.log(key)
        if ((participantsObj).hasOwnProperty(key)) {
          var value = participantsObj[key];
          console.log(value)
          if (key === "Frequent Flyer") {
            participantsObj.frequent_flyer = value;
          }
          else if (key === "Stim Completed") {
            participantsObj.stim_completed = value;
          }
          else if (key === "iPad Tasks") {
            participantsObj.ipad_tasks = value;
          }
          totalParticipants = totalParticipants + value; //do something with value;
        }
      }

      setTreatmentCount(participantsObj.Treatment || 0);
      setCompletedCount(participantsObj.Completed || 0);
      setPausedCount((participantsObj.Paused || 0) + (participantsObj.paused || 0));
      setFlyerCount(participantsObj.frequent_flyer || 0);
      setMainCount(participantsObj.Maintenance || 0);
      setgs120Count(response.data.devices.GS);
      setwatchCount(response.data.devices.Actigraph);
      setSiteCount(response.data.site_count);
      setStudyCount(response.data.study_count);
      setIpadDeviceCount(response.data.devices.iPad);
      setParticipantCount(totalParticipants);
      setTreatmentTime(response.data.total_therapy_time);
      setMsgCount(response.data.total_message_count);
      setSessionCount(response.data.total_session_count);
    }
    catch (error) {
      console.log(error)
      console.log(error);
    }
  }
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const data = [
    ((treatmentCount !== 0) ? { name: 'Group A', value: treatmentCount } : {}),
    ((pausedCount !== 0) ? { name: 'Group B', value: pausedCount } : {}),
    ((completedCount !== 0) ? { name: 'Group C', value: completedCount } : {}),
    ((flyerCount !== 0) ? { name: 'Group D', value: flyerCount } : {}),
    ((mainCount !== 0) ? { name: 'Group E', value: mainCount } : {}),
  ];

  if (role !== "6") {
    return (
      <div>
        <Typography variant="h3" align="center">
          <b style={{ color: COG_DARK }}>
            Home Page - Study Meter
          </b>
        </Typography>
        {token ?
          <div></div>
          :
          <Link to="/login">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Proceed to Login
            </Button>
          </Link>
        }
        {token ?
          <Grid container direction="row" alignItems="center" spacing={10} justifyContent="center">
            <Grid item>
              <Typography >
                <b style={{ color: COG_DARK }}>Stages of Participants</b>
              </Typography>
              <PieChart width={160} height={160}>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                 //label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </Grid>
            <Grid item>
              <Grid>
                <Typography variant="body1" align="left">
                  <b style={{ color: COLORS[0] }}>Treatment: {treatmentCount}</b>
                </Typography>
                <Typography variant="body1" align="left">
                  <b style={{ color: COLORS[1] }}>Paused: {pausedCount}</b>
                </Typography>
                <Typography variant="body1" align="left">
                  <b style={{ color: COLORS[2] }}>Study Completion: {completedCount}</b>
                </Typography>
                <Typography variant="body1" align="left">
                  <b style={{ color: COLORS[3] }}>Frequent Flyer: {flyerCount}</b>
                </Typography>
                <Typography variant="body1" align="left">
                  <b style={{ color: COLORS[4] }}>Maintenance: {mainCount}</b>
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid>
                <Typography variant="body1" align="center">
                  <b style={{ color: COG_DARK }}>Total Sites: {siteCount}</b>
                </Typography>
                <Typography variant="body1" align="center">
                  <b style={{ color: COG_DARK }}>Total Participants: {participantCount}</b>
                </Typography>
                <Typography variant="body1" align="center">
                  <b style={{ color: COG_DARK }}>Total Studies: {studyCount}</b>
                </Typography>
                <Typography variant="body1" align="center">
                  <b style={{ color: COG_DARK }}>Total GS120: {gs120Count}</b>
                </Typography>
                <Typography variant="body1" align="center">
                  <b style={{ color: COG_DARK }}>Total Actigraph: {watchCount}</b>
                </Typography>
                <Typography variant="body1" align="center">
                  <b style={{ color: COG_DARK }}>Total iPad: {ipadDeviceCount}</b>
                </Typography>
                <Typography variant="body1" align="center">
                  <b style={{ color: COG_DARK }}>Total Msg: {msgCount}</b>
                </Typography>
                <Typography variant="body1" align="center">
                  <b style={{ color: COG_DARK }}>Total Treatment Session: {sessionCount}</b>
                </Typography>
                <Typography>
                  <b style={{ color: COG_DARK }}>
                    Total Treatment Time: {(treatmentTime / 3600).toFixed(0)} Hours & {(
                      Math.abs((((treatmentTime / 3600).toFixed(1)) - ((treatmentTime / 3600).toFixed(0))).toFixed(1) * 60)
                    )} Mins
                  </b>
                </Typography>
                <Button href='/participants' color="secondary" variant="text" >{'View Participants'}</Button>
              </Grid>
            </Grid>
          </Grid>
          :
          <></>
        }
        {token ?
          <Grid container direction="row" alignItems="center" spacing={10} justifyContent="center" sx={{paddingTop:'80px'}} >
            <LinearWithLabel name="Early Adoption Rate" value={90} />
            <LinearWithLabel name="Stickiness Rate" value={15} />
            <LinearWithLabel name="Drop off Rate" value={10} />
          </Grid>
          :
          <></>
        }
        <Grid container direction="column" alignItems="center" spacing={10} justifyContent="center">
          <Grid item>
            <Button
              href={'/upload'}
              color="secondary"
              title="File Upload"
              disabled={true}
            >
              {'Quick Upload'}
            </Button>
            {(role === "3" || role === "0") ?
              <Button
                href={gsManagerDownloadLink}
                color="secondary" title="Download GSManager"
                download="GSManager"
              >
                Download GSManager
              </Button>
              :
              <></>
            }
          </Grid>
        </Grid>
      </div>
    );
  }
  else {
    return (<HandleRejection open={true} />);
  }
}

export default Home;
