import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { getRole, getToken, removeUserSession} from './Utils/Common';
import { DataGrid } from '@mui/x-data-grid';
import HandleRejection from './components/RoleRejection';

function WatchData(props) {
  const { params } = props.match;
  const role = getRole();
  const device_id = params.device_id;
  const token = getToken();
  const [ data, setData ] = useState([]);
  const [ count, setCount ] = useState(0);

  const config = {
    headers: { Authorization: `Bearer ${token}` }
   };

  // get events on init render
  useEffect(() => {
    getWatchData();
  }, [] );

  const getWatchData=async()=>{
    try {
        const url = '/api/v1/device/' + device_id;
        console.log(url)
        const response=await axios.get(url, config);
        console.log(response)
        if (response.status === 404 ){
          setData([]);
          setCount(0);
        } else {
          console.log(response.data.sessions)
          if(response.data.uploadFiles){
            let uploadFilesLength = response.data.uploadFiles.length;
            let uploadFilesData = response.data.uploadFiles[0];
            let dataStart = uploadFilesData.beginOfData;
            let dataEnd = uploadFilesData.endOfData;
            let wearTime = (new Date(dataEnd) - new Date(dataStart))/1000;
            let battLevel = response.data.activityMonitorBatteryVoltage;
            setData([{"id": 0, "start_date": dataStart, "end_date": dataEnd, 'wear_time': wearTime, "batt_level": battLevel}]);
            setCount(uploadFilesLength);
          }
          else {
            setData([]);
            setCount(0);
          }
        }
    } catch(error) {
        console.log(error);
        setData([]);
        setCount(0);
      	if (error.response.status !== 404) {
            removeUserSession();
            props.history.push('/login');
        }
    }
  }

  const sessionColumns = [
    {
      field: 'start_date',
      headerName: 'First Data',
      width: 200,
      sortable: false,
      editable: false,
    },
    {
      field: 'end_date',
      headerName: 'Latest Data',
      width: 200,
      sortable: false,
      editable: false,
    },
    {
      field: 'wear_time',
      headerName: 'Wear Time (m)',
      width: 200,
      sortable: false,
      editable: false,
    },
    {
      field: 'batt_level',
      headerName: 'Battery Level',
      width: 140,
      sortable: false,
      editable: false,
    },
  ];

  if(role !== "6") {
    return (
      <div>
        <div style={{width: "100%"}}>
          Watch Data for {device_id} :
          <br/>
          <br/>
          {count > 0 && (
            <div style={{background:"white"}}>
              <DataGrid
                color="secondary"
                rows={data}
                columns={sessionColumns}
                autoHeight={true}
                loading={data.length === 0}
                rowHeight={38}
                rowCount={count}
                checkboxSelection
                disableSelectionOnClick
                pagination
                paginationMode="server"
                pageSizeOptions={[20]}
              />
            </div>
          )}
          {count === 0 && (
            <div>No Watch Data Available for {device_id}</div>
          )}
  
  
        </div>
      </div>
    );
  }
  else {
    return(
      <HandleRejection open={true}/>
    );
  }
}

export default WatchData;
