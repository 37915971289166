import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken, getRole, removeUserSession } from './Utils/Common';
import { DataGrid } from '@mui/x-data-grid';
import { Snackbar, Dialog, DialogTitle, DialogContent, DialogActions, Button,
  Accordion, AccordionSummary, Typography, TextField, Alert, AccordionDetails, Box, FormControl, InputLabel, Select, MenuItem, } from '@mui/material';
import HandleRejection from './components/RoleRejection';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReportGenerator from './components/ReportRequest';
import parseSessionTime from './Utils/parseSessionTime';

function Devices(props) {
  const role = getRole();
  const token = getToken();
  const [gsDevices, setGSDevices] = useState([]);
  const [ipadDevices, setiPadDevices] = useState([]);
  const [gsCount, setGSCount] = useState(0);
  const [iPadCount, setiPadCount] = useState(0);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("Error: Please fill out all required fields");
  const [page, setPage] = useState(0);
  const [page2, setPage2] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [searchUnitID, setSearchUnitID] = useState("");
  const [filterKey, setFilterKey] = useState("");
  const [filter, setFilter] = useState("");

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  useEffect(() => {
    getDeviceList();
  }, [refresh]);

  const handleClose = () => {
    setOpenDialog(false);
  }

  const handleUnassignLabel = (unprocessedDevice) => {
    let deviceStatus = "";
    let deviceStatusBase = unprocessedDevice.slice(0, 8);
    let gscStatus = unprocessedDevice.slice(8, 10);
    let deviceStatusKey = unprocessedDevice.slice(9, 10);

    if (deviceStatusBase === "unassign") {
      deviceStatus = "unassigned";
    }
    else {
      deviceStatus = deviceStatusBase;
    }

    return deviceStatus + "(" + gscStatus + ")"


  }

  const handleEndDevice = (props) => {
    let url = `/api/v1/device/${props}`;
    axios.get(url, config).then(response => {
      console.log("assignment_id Flag", response);
      if (window.confirm("Are you sure you want to disassociate this device? ")) {
        url = '/api/v1/assignment/' + response.data.assignment.assignment_id;
        var data = {
          end_date: new Date().toISOString(),
        }
        console.log(data);
        axios.put(url, data, config).catch(e => {
          console.log('error');
          console.log(e);
          if (e.response.status !== 404) {
            removeUserSession();
            window.location.reload();
          }
        }).then(response => {
          console.log("Dissassociation Flag", response);
        });
        setRefresh(refresh + 1);
      }
      console.log("De-assign device cancelled.");
    }).catch(e => {
      console.log('error');
      console.log(e);
      setErrorText(e);
      setError(true);
      if (e.response.status !== 404) {
        removeUserSession();
        window.location.reload();
      }
    })
  }

  const handleDateParse = (dateVar) => {
    if (dateVar === 'Invalid Date') { return "None" }
    else { return dateVar; }
  }

  const parseDeviceLists = async (response, isGSDevice) => {
    let unprocessedDevices = response.data.devices;
    var parsedDevice;
    let gsLog = []
    let ipadLog = [];
    let dateVar, dateVal, eventDateVar, eventDateVal;
    console.log(unprocessedDevices)

    for (let i = 0; i < response.data.devices.length; i++) {
      dateVar = unprocessedDevices[i].last_message;
      dateVal  = handleDateParse(dateVar).substr(0,16);
      eventDateVar = unprocessedDevices[i].last_event;
      eventDateVal  = handleDateParse(eventDateVar).substr(0,16);
      parsedDevice = {'id': unprocessedDevices[i].id, 'unit_id': unprocessedDevices[i].unit_id,  'system_firmware': unprocessedDevices[i].system_firmware, 'device_type': unprocessedDevices[i].device_type, 
        'status': handleUnassignLabel(unprocessedDevices[i].status), 'alternate_id': unprocessedDevices[i].alternate_id, 'therapy_time': unprocessedDevices[i].therapy_time, 'sessions': unprocessedDevices[i].sessions, 
        'last_message': dateVal, 'last_event': eventDateVal
      };

      let DeviceType = parsedDevice.device_type;
      console.log(DeviceType)
      if (DeviceType == 0) {
        if (gsLog.length == 20) { return }
        gsLog.push(parsedDevice);
      }
      else {
        if (ipadLog.length == 20) { return }
        ipadLog.push(parsedDevice);
      }
    }
    console.log(parsedDevice)
    if (response.status === 404) {
      setGSDevices([]);
      setiPadDevices([]);
      setGSCount(0);
      setiPadCount(0);
    }
    else {
      console.log(gsLog);
      console.log(ipadLog.length === 0);
      if (isGSDevice) {
        setGSDevices(gsLog);
      }
      else {
        setiPadDevices(ipadLog);
      }
    }
  }

  const getDeviceList = async (next_page, next_page2) => {
    try {
      if (!next_page) {
        next_page = 1;
      }
      else if (next_page >= 1) {
        next_page = next_page + 1;
      }
      if (!next_page2) {
        next_page2 = 1;
      }
      else if (next_page2 >= 1) {
        next_page2 = next_page2 + 1
      }

      let url_gs = '/api/v1/devices?sort=unit_id&device_type=gs&unit_id=' + encodeURIComponent(searchUnitID.trim()) + '&page=' + next_page;
      let url_ipad = '/api/v1/devices?sort=unit_id&device_type=notgs&page=' + next_page2;
      if(filterKey) {
        url_gs+= `&status=${filterKey}`;
        url_ipad+= `&status=${filterKey}`
      }
      const response_gs = await axios.get(url_gs, config).catch(e => {
        console.log('error');
        console.log(e);
        if (e.response.status !== 404) {
          removeUserSession();
          window.location.reload();
        }
      });
      setGSCount(response_gs.data.count);
      const response_ipad = await axios.get(url_ipad, config).catch(e => {
        console.log('error');
        console.log(e);
        if (e.response.status !== 404) {
          removeUserSession();
          window.location.reload();
        }
      });
      setiPadCount(response_ipad.data.count)
      parseDeviceLists(response_gs, true);
      parseDeviceLists(response_ipad, false);
    } catch (error) {
      console.log(error.response);
      setGSDevices([]);
      setiPadDevices([]);
      setGSCount(0);
      setiPadCount(0);
    }
  }

  function getDeviceType(typeNumber) {
    switch (typeNumber) {
      case 0:
        return "GammaSense";
      case 1:
        return "Actigraph";
      case 2:
        return "iPAD";
      default:
        return typeNumber;
    }
  }

  const gsColumns = [
    {
      field: 'unit_id',
      headerName: 'Unit ID',
      width: 120,
      editable: false,
    },
    {
      field: 'system_firmware',
      headerName: 'Sytem Firmware',
      width: 160,
      sortable: false,
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 160,
      sortable: false,
      editable: false,
    },
    {
      field: 'therapy_time',
      headerName: 'Therapy Time',
      width: 160,
      sortable: false,
      editable: false,
      renderCell: (params) =>
        <div>
          {parseSessionTime(params.row.therapy_time)}
        </div>
    },
    {
      field: 'sessions',
      headerName: '# Sessions',
      width: 160,
      sortable: false,
      editable: false,
    },
    {
      field: 'last_message',
      headerName: 'Last Message',
      width: 170,
      sortable: false,
      editable: false,      
    },
    
    {
      field: 'last_event',
      headerName: 'Last Event',
      width: 170,
      sortable: false,
      editable: false,
    },
    {
      field: 'sd', // triggered note
      headerName: 'Actions',
      width: 150,
      sortable: false,
      editable: false,
      renderCell: (params) =>
        <div>
          <Button>
            <ReportGenerator
              urlName={`api/v1/device/${params.row.unit_id}/report`}
              fileName={`Device Report-${params.row.unit_id}`}
              fileDetail="All Device Data"
              windowTitle="Get Particle Report"
              windowTitlePrimary="Particle Report"
              windowTitleSecondary={`Are you sure you want to download the GS120 particle report for ${params.row.unit_id}?`}
              btn1Text={<DownloadIcon />}
              btn2Text="All Particle Data"
              btn3Text="Download by Date"
              buttonTitle="Download Particle Summary"
              dateLabel1={true}
              dateLabel2={true}
            />
          </Button>
        </div>
    },
  ];

  const iPadColumns = [
    {
      field: 'unit_id',
      headerName: 'Unit ID',
      width: 120,
      editable: false,
    },
    {
      field: 'device_type',
      headerName: 'Device Type',
      width: 160,
      sortable: false,
      editable: false,
      renderCell: (params) => getDeviceType(params.row.device_type)
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 160,
      sortable: false,
      editable: false,
    },
    {
      field: 'sessions',
      headerName: '# Sessions',
      width: 160,
      sortable: false,
      editable: false,
    },
    {
      field: 'last_message',
      headerName: 'Last Message',
      width: 170,
      sortable: false,
      editable: false,      
    },
    
    {
      field: 'last_event',
      headerName: 'Last Event',
      width: 170,
      sortable: false,
      editable: false,
    },
    {
      field: 'st', // triggered note
      headerName: 'Actions',
      width: 150,
      sortable: false,
      editable: false,
      renderCell: (params) =>
        <div>
          <Button color='secondary' onClick={() => { setOpenDialog(true); }}>
            <DownloadIcon />
            {/* <ReportGenerator 
                    urlName = {`api/v1/device/${params.row.unit_id}/report`} 
                    fileName = {`iPad Report-${params.row.unit_id}`} 
                    fileDetail = "All iPad Data"
                    windowTitle = "Get iPad Report" 
                    windowTitlePrimary="iPad Report"
                    windowTitleSecondary={`Are you sure you want to download the iPad report for ${params.row.unit_id}?`}
                    btn1Text={<DownloadIcon/>}
                    btn2Text = "All iPad Data"
                    btn3Text = "Download by Date"
                    buttonTitle="Download iPad Data"
                    dateLabel1={true}
                    dateLabel2={true}
                    /> */}
          </Button>
          {
            (((role === "0") || (role === "3") || (role === "2")) && (params.row.status.slice(0, 8) === "assigned")) ?
              <Button color='secondary' onClick={() => handleEndDevice(params.row.unit_id)}>
                <DeleteIcon />
              </Button>
              :
              <></>
          }
        </div>
    },
  ];

  function handleFilterRequest() {
    setGSDevices([]); //prompt loading symbol
    setRefresh(refresh + 1);
  }


  function clearFilter() {
    setSearchUnitID("");
    handleFilterRequest();
    setFilterKey("");
  }

  function handleFilterChange (e) {
    setFilter(e.target.value);
    setSearchUnitID("");
    setFilterKey("");
  }

  if ((role === "0") || (role === "3")) {
    return (
      <div>
        Devices (Total: {gsCount + iPadCount}):
        <br />
        <br />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography color="secondary"> Search GS120 Table</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ maxWidth: 200 }}>
              <FormControl fullWidth variant='outlined'>
                <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filter}
                  label="Filter"
                  onChange={(e) => handleFilterChange(e)}
                >
                  <MenuItem value={1}>Unit ID</MenuItem>
                  <MenuItem value={2}>Assignment Status</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {filter == 1 && (
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Unit ID"
                type="note"
                fullWidth
                variant="standard"
                value={searchUnitID}
                onChange={(e) => setSearchUnitID(e.target.value)}
              />
            )}
            <br />
            {filter == 2 && (
              <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Assignment Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterKey}
                label="Assignment Status"
                onChange={(e) => setFilterKey(e.target.value)}
              >
                <MenuItem value={"assigned"}>Assigned</MenuItem>
                <MenuItem value={"unassigned"}>Unassigned</MenuItem>
              </Select>
            </FormControl>
            )}
            <br />
            <Button color="secondary" variant="text" onClick={() => handleFilterRequest()}>
              Search
            </Button>
            {searchUnitID &&
              <>
                <Button color="secondary" variant="text" onClick={() => clearFilter()}>
                  Clear Filter
                </Button>
              </>
            }
          </AccordionDetails>
        </Accordion>
        
        <div style={{background:"white"}}>
          <DataGrid
            color="secondary"
            rows={gsDevices}
            columns={gsColumns}
            autoHeight={true}
            loading={gsDevices.length === 0}
            rowHeight={38}
            rowCount={gsCount}
            //checkboxSelection
            disableSelectionOnClick
            pagination
            paginationMode="server"
            onPaginationModelChange={(newPage) => { setPage(newPage.page); getDeviceList(newPage.page, page2); }}
            paginationModel={{page:page, pageSize:20}}
          />
        </div>
        {(searchUnitID && gsDevices.length === 0) &&
          <>
            <div style={{ color: "red" }}>Your search returned 0 results</div>
          </>
        }
        <br/>
        <br/>
        <div style={{background:"white"}}>
          <DataGrid
            color="secondary"
            rows={ipadDevices}
            columns={iPadColumns}
            autoHeight={true}
            loading={ipadDevices.length === 0}
            rowHeight={38}
            rowCount={iPadCount}
            //checkboxSelection
            disableSelectionOnClick
            pagination
            paginationMode="server"
            onPaginationModelChange={(newPage) => { setPage2(newPage.page); getDeviceList(page, newPage.page); }}
            paginationModel={{page:page2, pageSize:20}}
          />
        </div>
        <Dialog open={openDialog} onClose={handleClose}>
          <DialogTitle>Feature not yet Implemented</DialogTitle>
          <DialogContent>This feature is not yet implemented. Check back later for updates.</DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={error} autoHideDuration={6000} onClose={() => { setError(false) }} sx={{position:'static'}}>
          <Alert onClose={() => { setError(false) }} severity="error" sx={{ width: '100%' }}>
            {errorText}
          </Alert>
        </Snackbar>
        <div>
          {/* <Link to='/device'>{(role==='0')?'Create Device':''}</Link> */}
        </div>
      </div>
    );
  }
  else {
    return (<HandleRejection open={true} />);
  }
}

export default Devices;