import React, {useState, useEffect } from 'react';
import axios from 'axios';
import { getToken, removeUserSession } from '../Utils/Common';
import { Dialog, DialogTitle, DialogContentText ,DialogContent, DialogActions, Button, TextField, CircularProgress, Backdrop } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const ReportGenerator = ({urlName, fileName, fileDetail, windowTitlePrimary, windowTitleSecondary, btn1Text, btn2Text,btn3Text, buttonTitle, dateLabel1, dateLabel2, filterStr}) => {
const [reportID, setReportID] = useState(null);
const [reportData, setReportData] = useState(null);
const [startDate, setStartDate] = useState(new Date().toISOString());
const [endDate, setEndDate] = useState(new Date().toISOString());
const [validDate, setValidDate] = useState(false);
const [download, setDownload] = useState(false);
const [openDialog, setOpenDialog] = useState(false);
const [loading, setLoading] = useState(true);
const [refresh, setRefresh] = useState(0);
const [openLoad, setOpenLoad] = useState(false);
const [filter, setFilter] = useState(filterStr);
let token = getToken();

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const config = {
  headers: { Authorization: `Bearer ${token}` }
};

const handleClose = () => {
  setOpenDialog(false);
}

const handleCloseLoading = () => {
  setOpenLoad(false);
};

const handleDownloadFile = () => {
  downloadFile();
}

const downloadFile = () => {
    console.log("downloadFile");
    let dateStr = `${startDate.replaceAll(".", "")}:${endDate.replaceAll(".", "")}`;
    const dataStr = reportData.toString();
    console.log(reportData);
    if(validDate){
      fileName = `${fileName}: Date Range : ${dateStr}`;
    }
    else{
      fileName = `${fileName}: ${fileDetail}`;
    }
    const blob = new Blob([dataStr], {type: 'text/csv'});
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
    })
    a.dispatchEvent(clickEvt);
    a.remove();
    setReportID(null);
    setReportData(null);
    setStartDate(new Date().toISOString());
    setEndDate(new Date().toISOString());
    setValidDate(false);
    setDownload(false);
    setOpenDialog(false);
    setLoading(true);
    setOpenLoad(false);
    handleClose();
}

const handleRequestDownload = (downloadByDate) => {
    setOpenLoad(true);
    let url = urlName;
    let status = "";
    console.log(filterStr)
    if(filterStr !== undefined){
      status = `${filterStr}`
    }
    console.log("handleRequestDownload");
    if(downloadByDate) {
      let newStartDate = startDate.substring(0, 10) + "T00:00:00.000Z"; //Set the start date's time to the beginning of the day for any download request
      let newEndDate = endDate.substring(0, 10) + "T23:59:59.999Z"; //Set the end date's time to the end of the day for any download request
      setStartDate(newStartDate);
      setEndDate(newEndDate);
      
      url = `${url}?start_date=${newStartDate}&end_date=${newEndDate}&${status}`
    }
    else if(filterStr){
      url = `${url}?${status}`
    }
    else{
      url = `${url}${status}`
    }
    axios.get(url, config).then(response => {
        console.log(response);
        setReportID(response.data.report_id);
        return
     }).catch(e => {
      console.log('error');
      console.log(e);
      if (e.response.status !== 404) {
        removeUserSession();
        window.location.reload();
      }
    });
} 

const handleGetReportData = () => {
    return GetReportData();
}

async function GetReportData(){
  await sleep(500);
  console.log("handleGetReportData")
  let id = reportID;
  let url = `/api/v1/report/status/${id}`
  axios.get(url, config).then(response => {
    if(response.data.status){
      setLoading(true);
      
      setRefresh(refresh+1);
     }
    else{
      setLoading(false);
      setReportData(response.data);
      console.log(response.data);
      return
    }
  }).catch(e => {
    console.log('error');
    console.log(e);
    if (e.response.status !== 404) {
      removeUserSession();
      window.location.reload();
    }
  });
}

useEffect(() => {
  console.log(`${loading} : ${reportID}`);
  if(reportID!=null){
    handleGetReportData(reportID);
  }
}, [reportID, refresh] );

useEffect(() => {
  console.log(`useEffect2: ${reportData}`)
  if(download === true && reportData!=null){
    handleDownloadFile();
}
}, [reportData] );

  return (
    <div> 
      {openLoad ? 
        <>        
          <Backdrop 
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openLoad}
            onClick={handleCloseLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </> 
      : 
        <>
          <Button 
            title={buttonTitle}
            variant='text'
            color='secondary'
            onClick ={()=>{ setOpenDialog(true); }}
          >
            {btn1Text}
          </Button>
          <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>{windowTitlePrimary}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {windowTitleSecondary}
              </DialogContentText>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {dateLabel1 ?
                  <DesktopDatePicker
                    label={dateLabel1}
                    value={startDate}
                    onChange={(e) => {
                      console.log(e)
                      let startDateFmt = new Date(e)
                      setStartDate((startDateFmt).toISOString());}}
                    renderInput={(params) => <TextField {...params} variant='standard'/>}
                  />
                :
                  <></>
                }
              </LocalizationProvider>
              &ensp;&ensp;
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {dateLabel2 ?
                  <DesktopDatePicker
                    label={dateLabel2}
                    value={endDate}
                    onChange={(e) => {
                      let endDateFmt = new Date(e)
                      setEndDate((endDateFmt).toISOString());}}
                    renderInput={(params) => <TextField {...params} variant='standard'/>}
                  />
                :
                  <></>
                }
              </LocalizationProvider>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button 
                onClick={() => {
                  handleRequestDownload(false);
                  setDownload(true);
                }}
              >
                {btn2Text}
              </Button>
              {btn3Text ?
                <Button 
                  onClick={() => {
                    setValidDate(true);
                    handleRequestDownload(true);
                    setDownload(true); 
                  }}
                >
                  {btn3Text}
                </Button>
              :
                <></>
              }
            </DialogActions> 
          </Dialog>
        </>
      }
    </div>
  )
};

export default ReportGenerator;
