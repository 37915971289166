import axios from "axios";
// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem('user');
  return userStr;
}

// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem('token') || null;
}

// return the token from the session storage
export const getRole = () => {
  return localStorage.getItem('role') || null;
}

// return the privilege from the session storage
export const getPriv = () => {
  return localStorage.getItem('privilege') || null;
}

// return the token from the session storage
export const getFName = () => {
  return localStorage.getItem('fname') || null;
}

// return the token from the session storage
export const getLName = () => {
  return localStorage.getItem('lname') || null;
}
// return the studies from the session storage
export const getStudies = () => {
  return localStorage.getItem('studies') || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('role');
  localStorage.removeItem('privilege');

}

// set the token and user from the session storage
export const setUserSession = (token, user, role, priv, fname, lname, studies) => {
  localStorage.setItem('token', token);
  localStorage.setItem('user', user);
  localStorage.setItem('role', role);
  localStorage.setItem('privilege', priv);
  localStorage.setItem('fname', fname);
  localStorage.setItem('lname', lname);
  localStorage.setItem('studies',studies)
}

// set the edit user data
export const setEditData = (user, role) => {
  console.log('setEditData:', user, role);
  localStorage.setItem('edit_user', user);
  localStorage.setItem('edit_role', role);
}

// get the edit user data
export const getEditUser = () => {
  var user = localStorage.getItem('edit_user');
  console.log('getEditUser:', user);
  return user;
}

// get the edit user data
export const getEditRole = () => {
  var role = localStorage.getItem('edit_role');
  console.log('getEditRole:', role);
  return role;
}
//get API version
export const getAPIVersion = () => {
  var version = localStorage.getItem('api_version');
  console.log('api_version:', version);
  return version;
}

// set the edit device data
export const setEditDeviceData = (id, altid, fw, active) => {
  console.log('setEditDeviceData:', id, altid, fw, active);
  localStorage.setItem('edit_id', id);
  localStorage.setItem('edit_altid', altid);
  localStorage.setItem('edit_fw', fw);
  localStorage.setItem('edit_active', active);
}

// get the edit device data
export const getEditDeviceData = () => {
  var id = localStorage.getItem('edit_id');
  var altid = localStorage.getItem('edit_altid');
  var fw = localStorage.getItem('edit_fw');
  var active = localStorage.getItem('edit_active');
  var data = { 'id':id, 'altid':altid, 'fw':fw, 'active':active }
  console.log('device data:', data);
  return data;
}

//get the api version
export function setAPIVersion(props) {
  const token = getToken();
  
  const config = {
    headers: { Authorization: `Bearer ${token}` }
   };

  const getAPIVersion=async()=>{
    try {
      const url = '/api/version';
      const response=await axios.get(url);
      console.log(response.data);
      localStorage.setItem('api_version', response.data.version);
      return response.date.version
     
    } catch(error) {
        console.log(error.response);
    }

  }
  let result = getAPIVersion();
  console.log(result)
}
