import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Typography, Grid, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Backdrop } from '@mui/material';
import { getToken, getRole, removeUserSession } from './Utils/Common';
import EnableStatus from './components/StatusDialog';
import HandleRejection from './components/RoleRejection';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import StubReport from './components/StubReport';
import ToggleNotification from './components/PlayPauseDialog';
import ReportGenerator from './components/ReportRequest';
import parseSessionTime from './Utils/parseSessionTime';

function Patients(props) {
  const { params } = props.match;
  const patient_id = params.participant_id;
  const [zipCode, setZipCode] = useState(null);
  const [randCode, setRandCode] = useState(null);
  const [sessionCount, setSessionCount] = useState(null);
  const [treatmentTime, setTreatmentTime] = useState(null);
  const [stub, setStub] = useState(false);
  const [devSerial, setDevSerial] = useState(null);
  const [devInfo, setDevInfo] = useState(null);
  const [devTicket, setDevTicket] = useState(null);
  const [opInfo, setOpInfo] = useState(null);
  const [opId, setOpId] = useState(null);
  const [statHistory, setStatHistory] = useState([]);
  const role = getRole();
  const token = getToken();
  const [patient, setPatient] = useState(null);
  const [devices, setDevices] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [upload, setUpload] = useState([]);
  const [uploadCount, setUploadCount] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);
  const [deviceCount, setDeviceCount] = useState(0);
  const [participantDetails, setParticipantDetails] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [deviceType, setDeviceType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [refresh, setRefresh] = useState(0);
  const [tgrSessionID, setTgrSessionID] = useState('');
  const [protocolID, setProtocolID] = useState('');
  const [compliantReportdata, setCompliantReportsData] = useState([]);
  const [provisionTitle, setProvisiontitle] = useState('Days Since Provisioning')
  const dashColors = ["red", "black", "grey"];

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const handleEditRequest = (note, row, statusStr) => {
    console.log("EditRow: ", row, "EditReq:", statusStr);
    let notificationurl = "/api/v1/notification/" + row;
    let data = { notes: note, status: statusStr };
    axios.put(notificationurl, data, config).then(response => {
      console.log('in edit put response');
      //console.log(response);
    }).catch(e => {
      console.log('error');
      console.log(e);
      if (e.response.status !== 404) {
        removeUserSession();
        window.location.reload();
      }
    });
    console.log(refresh);
    setRefresh(refresh + 1);
  }

  const handlePauseRequest = (note, row) => {
    console.log("PausedRow: ", row);
    let notificationurl = "/api/v1/notification/" + row;
    let data = { notes: note, status: "paused" };
    axios.put(notificationurl, data, config).then(response => {
      console.log('in pause put response');
      console.log(response);
    }).catch(e => {
      console.log('error');
      console.log(e);
      if (e.response.status !== 404) {
        removeUserSession();
        window.location.reload();
      }
    });
    setTimeout(() => setRefresh(refresh + 1), 1000);
  }

  const handleActiveRequest = (note, row) => {
    let newNote = note.replaceAll(":", "");
    let notificationurl = "/api/v1/notification/" + row
    let data = { notes: newNote, status: "active" }
    axios.put(notificationurl, data, config).then(response => {
      console.log('in active put response');
      console.log(response);
    }).catch(e => {
      console.log('error');
      console.log(e);
      if (e.response.status !== 404) {
        removeUserSession();
        window.location.reload();
      }
    });
    setTimeout(() => setRefresh(refresh + 1), 1000);
  }

  const handleStubReport = (params) => {
    let fetchData = async () => {
      console.log(params.row.id);
      const url = `${process.env.REACT_APP_API_URL}/api/v1/assignment/${params.row.id}`;
      console.log(refresh);
      setRefresh(refresh + 1);
      return await axios.get(url, config).catch(e => {
        console.log('error');
        console.log(e);
        if (e.response.status !== 404) {
          removeUserSession();
          window.location.reload();
        }
      });
    }

    fetchData().then(response => {
      console.log(response);
      let tgr_session_id = "N/A";
      let tgr_protocol_id = "N/A";
      if (params.row.device_type === "GS120") {
        if (response.data.comments) {
          let tgrInfo = (response.data.comments).split(",");
          if (tgrInfo[0] !== "undefined") {
            tgr_session_id = tgrInfo[0];
          }
          if (tgrInfo[1] !== "undefined") {
            tgr_protocol_id = tgrInfo[1];
          }
        }
      }

      setOpInfo(response.data.information);
      setOpId(response.data.operator_id);
      setDeviceType(params.row.device_type);
      setDevSerial(params.row.unit_id);
      setDevInfo(params.row.details);
      setDevTicket(params.row.id);
      setRandCode(response.data.randomization_code);
      setStartDate(response.data.start_date);
      setEndDate(response.data.end_date);
      setProtocolID(tgr_protocol_id);
      setTgrSessionID(tgr_session_id);
      setStub(true);
    });
  }

  useEffect(() => {
    getPatient();
  }, [refresh]);

  useEffect(() => {
    if (isPrinting) {
      setTimeout(() => { window.print(); }, 500); //Allow dropdowns to render first
      setIsPrinting(false);
    }
  }, [isPrinting]);

  const handleDateParse = (dateStr) => {
    let year = dateStr.substring(0, 4);
    let month = dateStr.substring(5, 7);
    let day = dateStr.substring(8, 10);
    let dateRes = `${month}/${day}/${year}`;
    return dateRes;
  }

  const getPatient = async () => {
    let patientDevices = [];
    let patientNotifications = [];
    let patientDetails = [];
    let patientUploads = [];
    let h_status = [];
    let assignmentUrl = '/api/v1/patient/';
    let patientResponse = await axios.get(assignmentUrl + patient_id, config).catch(e => {
      console.log('error');
      console.log(e);
      if (e.response.status !== 404) {
        removeUserSession();
        window.location.reload();
      }
    });
    console.log(patientResponse)

    let reportResponse = await axios.get(assignmentUrl + patient_id + '/uploads', config).catch(e => {
      console.log('error');
      console.log(e);
      if (e.response.status !== 404) {
        removeUserSession();
        window.location.reload();
      }
    });
    console.log(reportResponse)

    let notifications = patientResponse.data.participant_detail.notifications;
    if (role !== '3') { //For non-MO roles, we can query the notifications endpoint
      let notificationsResponse = await axios.get('api/v1/notifications?subject=' + patient_id, config).catch(e => {
        console.log('error');
        console.log(e);
        if (e.response.status !== 404) {
          removeUserSession();
          window.location.reload();
        }
      });
      console.log(notificationsResponse)
      notifications = notificationsResponse.data.notifications;
    }

    let patientData = patientResponse.data.participant_detail;
    setZipCode(patientData.zip_code);
    setSessionCount(patientData.therapy_details.session_count);
    setTreatmentTime(patientData.therapy_details.therapy_time);
    let length = patientData.registration_history.length;

    for (let i = 0; i < length; i++) {
      let entry = { "id": [i], "date": patientData.registration_history[i].status_date.split(".")[0], "history": patientData.registration_history[i].historical_status };
      h_status.push(entry);
      console.log(h_status);
    }
    setStatHistory(h_status);
    console.log(h_status);

    //console.log(patientData.therapy_details)
    if (patientResponse.status !== 404) {
      //To parse api for Participant table
      let parsedPatient = [{
        "id": patientData.subject_id,
        "study_id": patientData.study_id,
        "site_id": patientData.site_id,
        "adherence": patientData.notification_count,
        "stage": patientData.registration_status,
        "consecutive_compliant_days": patientData.consecutive_compliant_days,
        "total_compliant_days": patientData.total_compliant_days,
        "days_since_treatment": patientData.days_since_treatment,
        "days_in_stage": patientData.days_in_stage,
        "days_since_provisioning": patientData.days_since_provisioning
      }];
      setPatient(parsedPatient);
      getCompliantData(patientData, length)
      //To parse api for Devices table
      let assignments = patientData.assignments;
      console.log(patientData)
      let numAssignments = assignments.length;
      let assignment;
      let details;

      if (numAssignments > 0) {
        for (let i = 0; i < numAssignments; i++) {
          let startDateVar = assignments[i].start_date;
          let endDateVar = assignments[i].end_date;

          let startDateOffset = startDateVar.toString();
          let endDateOffset = endDateVar.toString();
          let endDate;
          if (assignments[i].end_date.slice(0, 10) === "9999-12-31") {
            endDate = "N/A";
          }
          else {
            endDate = handleDateParse(endDateOffset);
          }
          assignment = {
            "id": assignments[i].assignment_id,
            "device_type": assignments[i].device,
            "start_date": handleDateParse(startDateOffset),
            "end_date": endDate,
            "unit_id": assignments[i].unit_id,
            "details": assignments[i].therapy_details,
            "assigned": assignments[i].status,
            "adherence": assignments[i].adherence,
          };

          patientDevices.push(assignment);
          details = {
            "id": assignments[i].assignment_id,
            "device_type": assignments[i].device,
            "details": assignments[i].therapy_details
          };
          patientDetails.push(details);

        }
      }
      setDeviceCount(numAssignments);
      console.log("**Num Assignments**", patientDevices);
      setDevices(patientDevices);
      setParticipantDetails(patientDetails);
      //To parse api for Notification table

      let notification;
      for (let i = 0; i < notifications.length; i++) {
        let dateVar = handleDateParse(notifications[i].notification_date);
        notification = {
          "id": notifications[i].id,
          "notification_date": dateVar,
          "device_type": notifications[i].device_type,
          "device_id": notifications[i].unit_id,
          "n_session_count": notifications[i].content.session_count,
          "n_sessions_total": notifications[i].content.period,
          "rule": notifications[i].content.name,
          "status": notifications[i].status,
          "notes": notifications[i].notes
        };
        console.log(notifications);
        patientNotifications.push(notification);
      }

      setNotificationCount(notifications.length);
      setNotifications(patientNotifications);
    }
    if (reportResponse.data.count > 0) {
      let fileDetails = reportResponse.data.uploads;
      for (let i = 0; i < reportResponse.data.count; i++) {
        let file = {
          "id": fileDetails[i].id,
          "device_id": fileDetails[i].device_id,
          "filename": fileDetails[i].filename,
          "location": fileDetails[i].location,
        };
        patientUploads.push(file);
      }
      console.log(patientUploads);
      setUpload(patientUploads);
      setUploadCount(patientUploads.length);
    }

  }

  const historyColumns = [
    {
      field: 'date',
      headerName: 'Date',
      width: 400,
      sortable: false,
      editable: false,
    },
    {
      field: 'history',
      headerName: 'History',
      width: 400,
      sortable: false,
      editable: false,
      renderCell: (params) => getDeviceType(params.row.device_type)
    },
  ];

  //Columns for Device Assignments Table
  const assignmentColumns = [
    {
      field: 'unit_id',
      headerName: 'Device ID',
      width: 150,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        let fontColor = 1;
        if (params.row.assigned !== "assigned") {
          fontColor = 0.4;
        }
        return (
          <div style={{ opacity: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'device_type',
      headerName: 'Device Type',
      width: 160,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        let fontColor = 1;
        let deviceType = getDeviceType(params.row.device_type);
        if (params.row.assigned !== "assigned") {
          fontColor = 0.4;
        }
        return (
          <div style={{ opacity: fontColor }}> {deviceType} </div>
        );
      }
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      width: 120,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        let fontColor = 1;
        if (params.row.assigned !== "assigned") {
          fontColor = 0.4;
        }
        return (
          <div style={{ opacity: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      width: 120,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        let fontColor = 1;
        let endDate = getEndDate(params.row.end_date)
        if (params.row.assigned !== "assigned") {
          fontColor = 0.4;
        }
        return (
          <div style={{ opacity: fontColor }}> {endDate} </div>
        );
      }
    },
    {
      field: 'adherence',
      headerName: 'Adherence',
      width: 130,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        let fontColor = 1;
        if (params.row.assigned !== "assigned") {
          fontColor = 0.4;
        }
        if(params.value.includes('|')){
        return (
          <div style={{ opacity: fontColor }}> {params.value.split('|')[0]} </div>
        );
        }
        return (
          <div style={{ opacity: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'details',
      headerName: 'Details',
      width: 320,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        let fontColor = 1;
        if (params.row.assigned !== "assigned") {
          fontColor = 0.4;
        }
        return (
          <div style={{ opacity: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'sd', // triggered note
      headerName: 'Actions',
      width: 150,
      sortable: false,
      editable: false,
      renderCell: (params) =>
        <div>
          <Button
            onClick={() => handleStubReport(params)}
            title="Get Assignment Report"
            color="secondary">
            <SimCardDownloadIcon />
          </Button>
        </div>
    },
  ];

  //Columns for Notifications Table
  const notificationColumns = [
    {
      field: 'notification_date',
      headerName: 'Notification Date',
      width: 160,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        let fontColor = dashColors[0];
        let opacity = 1;
        if (params.row.status !== "active") {
          fontColor = dashColors[1];
          opacity = 0.4;
        }
        return (
          <div style={{ color: fontColor, opacity: opacity }}> {params.value} </div>
        );
      }
    },
    {
      field: 'device_id',
      headerName: 'Device ID',
      width: 120,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        let fontColor = dashColors[0];
        let opacity = 1;
        if (params.row.status !== "active") {
          fontColor = dashColors[1];
          opacity = 0.4;
        }
        return (
          <div style={{ color: fontColor, opacity: opacity }}> {params.value} </div>
        );
      }
    },
    {
      field: 'device',
      headerName: 'Device Type',
      width: 160,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        let fontColor = dashColors[0];
        let opacity = 1;
        if (params.row.status !== "active") {
          fontColor = dashColors[1];
          opacity = 0.4;
        }
        return (
          <div style={{ color: fontColor, opacity: opacity }}> {getDeviceType(params.row.device_type)} </div>
        );
      }
    },
    {
      field: 'status', // triggered note
      headerName: 'Alert Status',
      width: 160,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        let fontColor = dashColors[0];
        let opacity = 1;
        if (params.row.status !== "active") {
          fontColor = dashColors[1];
          opacity = 0.4;
        }
        return (
          <div style={{ color: fontColor, opacity: opacity }}> {params.value} </div>
        );
      }
    },
    {
      field: 'rule', // triggered note
      headerName: 'Rule Broken',
      width: 160,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        let fontColor = dashColors[0];
        let opacity = 1;
        if (params.row.status !== "active") {
          fontColor = dashColors[1];
          opacity = 0.4;
        }
        return (
          <div style={{ color: fontColor, opacity: opacity }}> {params.value} </div>
        );
      }
    },
    {
      field: 'notes', // triggered note
      headerName: 'Ticket Number',
      width: 220,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        let fontColor = dashColors[0];
        let opacity = 1;
        if (params.row.status !== "active") {
          fontColor = dashColors[1];
          opacity = 0.4;
        }
        return (
          <div style={{ color: fontColor, opacity: opacity }}> {params.value} </div>
        );
      }
    },
    {
      field: 'active', // triggered note
      headerName: 'Actions',
      width: 170,
      sortable: false,
      editable: false,
      renderCell: (params) =>
        <div>
          <Button title="Edit Notification" >
            <ToggleNotification prevState={params.row.notes}
              row={params.row}
              label={<EditIcon />}
              title="Edit Notification"
              editNotes={true}
              activate={false}
              func1={handleActiveRequest}
              func2={handlePauseRequest}
              func3={handleEditRequest}
            />
          </Button>
          {(params.row.status === "active" || params.row.status === "paused") &&
            <>
              {params.row.status === "active" ?
                <Button title="Pause Notification">
                  <ToggleNotification
                    prevState={params.row.notes}
                    row={params.row}
                    label={<PauseIcon />}
                    title="Pause Notification"
                    activate={true}
                    func1={handleActiveRequest}
                    func2={handlePauseRequest}
                    func3={handleEditRequest} />
                </Button>
                :
                <Button title="Activate Notification">
                  <ToggleNotification
                    label={<PlayArrowIcon />}
                    prevState={params.row.notes}
                    row={params.row}
                    title="Activate"
                    activate={false}
                    func1={handleActiveRequest}
                    func2={handlePauseRequest}
                    func3={handleEditRequest} />
                </Button>
              }
            </>
          }
        </div>
      ,
    },
  ];

  const uploadColumns = [
    {
      field: 'device_id',
      headerName: 'Device ID',
      width: 160,
      sortable: true,
      editable: false,
      renderCell: (params) => getDeviceType(params.row.device_type)
    },
    {
      field: 'filename',
      headerName: 'File Name',
      width: 460,
      sortable: true,
      editable: false,
    },

    {
      field: 'location',
      headerName: 'File Path',
      width: 380,
      sortable: true,
      editable: false,
    },
    {
      field: '', // triggered note
      headerName: 'Actions',
      width: 150,
      sortable: false,
      editable: false,
      renderCell: (params) =>
        <Button
          onClick={() => downloadComplianceReport(params.row.id)}
          color="secondary" title="Download Report"
          download="GSManager">
          <DownloadIcon />
        </Button>
      ,
    },
  ];

  const getCompliantData = (patientData, length) => {

    if (patientData) {
      let days_since_provisioning = patientData.days_since_provisioning;
      if (days_since_provisioning === -1){
        days_since_provisioning = 'N/A';
      }

      let days_in_stage=  patientData.days_in_stage;
      if(patientData.days_in_stage === -1){
        days_in_stage = 'N/A';
      }

      let total_compliant_days = patientData.total_compliant_days;
      if (total_compliant_days < 0){
        total_compliant_days = 'N/A'
      }

      let data = [
        {
          id:1,
          days_since_provisioning: days_since_provisioning,
          days_in_stage: days_in_stage,
          total_compliant_days: total_compliant_days,  
        },
      ];
      setCompliantReportsData(data)
    } 
  }

  // Columns for Compliance Table
  const complianceColumns = [
    {
      field: 'days_since_provisioning',
      headerName: 'Number of Days Since Provisioning',
      width: 380,
      sortable: true,
      editable: false,
    },
    {
      field: 'days_in_stage',
      headerName: 'Number of Days in Current Study Stage',
      width: 380,
      sortable: true,
      editable: false,
    },
    {
      field: 'total_compliant_days',
      headerName: (
        <div style ={{ lineHeight: '1.2'}}>
          Number of Days for Compliance
          <br />
          (since last non-compliance cleared)
        </div>
      ),
      width: 380,
      sortable: true,
      editable: false,
    },
  ];
  let compliantReportRowsData = compliantReportdata.length;

  const downloadComplianceReport = async (id) => {
    let downloadUrl = '/api/v1/patient/' + patient_id + '/upload/' + id;
    let reportResponse = await axios.get(downloadUrl, config).catch(e => {
      console.log('error');
      console.log(e);
      if (e.response.status !== 404) {
        removeUserSession();
        window.location.reload();
      }
    });
    console.log(reportResponse)
    window.open(reportResponse.data.url)
  }

  //function for reporting binary alert status from device notification count
  function getEndDate(endDate) {
    if (endDate == "9999-12-31") {
      return "N/A";
    }
    else return endDate;
  }

  function getDeviceType(typeNumber) {
    switch (typeNumber) {
      case 0:
        return "GS120";
      case 1:
        return "Actigraph";
      case 2:
        return "iPAD";
      default:
        return typeNumber;
    }
  }

  if ((role === "0" || role === "2" || role === "3") && !stub) {
    return (
      <div>
        {patient ?
          <>
            <Typography variant="h5"> {patient_id} : <b><i>{zipCode || "None"}</i></b></Typography>
            <Typography variant="h6">Current Study State: {patient[0].stage || "No Stage Data Available"}</Typography>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography><b>Available Stage History:</b></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{background:"white"}}>
                  <DataGrid
                    color="secondary"
                    rows={statHistory}
                    columns={historyColumns}
                    autoHeight={true}
                    rowHeight={38}
                    rowCount={deviceCount}
                    //checkboxSelection
                    disableSelectionOnClick
                  />
                </div>
              </AccordionDetails>
            </Accordion>
            <div style={{ width: "100%" }}>
              <Grid container spacing={0}>
                <Grid item xs={4}>
                  <Button onClick={() => setRefresh(refresh + 1)} disabled={role === "3"}>
                    <EnableStatus
                      props={patient_id}
                      label="Edit Stage"
                      stage={patient[0].stage} />
                  </Button>
                </Grid>
                {deviceCount > 0 &&
                  <>
                    <Grid item xs={4}>
                      <ReportGenerator
                        urlName={`api/v1/patient/${patient_id}/session_report`}
                        fileName={`Session Report-${patient_id}`}
                        fileDetail="All Session Data"
                        windowTitle="Download GS120 Sessions"
                        windowTitlePrimary="Sessions Report"
                        windowTitleSecondary={`Are you sure you want to download the GS120 sessions report for ${patient_id}?`}
                        btn1Text="Download GS120 Sessions"
                        btn2Text="Confirm"
                        buttonTitle="Download GS120 Sessions"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <ReportGenerator
                        urlName={`api/v1/patient/${patient_id}/report`}
                        fileName={`Messages Report-${patient_id}`}
                        fileDetail="All Session Data"
                        windowTitle="Download GS120 Messages"
                        windowTitlePrimary="GS120 Messages Report"
                        windowTitleSecondary={`Are you sure you want to download the GS120 messages report for ${patient_id}?`}
                        btn1Text="Download GS120 Messages"
                        btn2Text="All Message Data"
                        btn3Text="Download by Date"
                        buttonTitle="Download GS120 Messages"
                        dateLabel1={true}
                        dateLabel2={true}
                      />
                    </Grid>
                  </>
                }
                <Grid item xs={12}>
                  <div>
                    Total Treatment Session: {sessionCount}, Total Treatment Time: {parseSessionTime(treatmentTime)}
                  </div>
                </Grid>
              </Grid>
              <br />
              <Grid item xs={12}>
              <div>Compliance Information for {patient_id}:</div>
              <DataGrid
                color="secondary"
                rows={compliantReportdata}
                columns={complianceColumns}
                autoHeight={true}
                loading={compliantReportdata.length === 0}
                rowHeight={38}
                hideFooter={true}
                //checkboxSelection
                disableSelectionOnClick
              />
            </Grid>
            <br />
              <Grid item xs={6}>
                <div>Assignments for {patient_id} (Total: {deviceCount} ):</div>
              </Grid>
              <br />
              {(deviceCount  > 0) ?
                <DataGrid
                  color="secondary"
                  rows={devices}
                  columns={assignmentColumns}
                  autoHeight={true}
                  loading={devices.length === 0}
                  rowHeight={38}
                  rowCount={deviceCount}
                  //checkboxSelection
                  disableSelectionOnClick
                /> : <></>
              }
            </div>
            <br />
            <div style={{ width: "100%" }}>
              Notifications for {patient_id} (Total: {notificationCount} ):
              <div style={{ background: "white" }}>
                {(notificationCount > 0) ?
                  <DataGrid
                    color="secondary"
                    rows={notifications}
                    columns={notificationColumns}
                    autoHeight={true}
                    loading={notifications.length === 0}
                    rowHeight={38}
                    rowCount={notificationCount}
                    //checkboxSelection
                    disableSelectionOnClick
                  /> : <></>
                }
              </div>
            </div>
            <br />
            <div style={{ width: "100%" }}>
              Compliance Reports for {patient_id} (Total: {uploadCount} ):
              <div style={{ background: "white" }}>
                {(uploadCount > 0) ?
                  <DataGrid
                    color="secondary"
                    rows={upload}
                    columns={uploadColumns}
                    autoHeight={true}
                    loading={upload.length === 0}
                    rowHeight={38}
                    rowCount={uploadCount}
                    //checkboxSelection
                    disableSelectionOnClick
                  /> : <></>
                }
              </div>
            </div>
          </>
          :
          <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </>
        }
      </div>
    );
  }
  else if (stub) {
    let input = {
      subject_id: patient_id,
      zip_code: zipCode,
      gs_sernum: devSerial,
      operator_info: opInfo,
      watch_sernum: devSerial,
      device_info_watch: devInfo,
      op_notes_w: devTicket,
      ipad_sernum: devSerial,
      device_info_ipad: devInfo,
      op_notes_t: devTicket,
      device_type: deviceType,
      device_date: handleDateParse(startDate),
      end_date: handleDateParse(endDate),
      operator_id: opId,
      rcode: randCode,
      tgr_session_id: tgrSessionID,
      protocol_id: protocolID,
    }
    return <StubReport props={input} />
  }
  else {
    return (
      <HandleRejection open={true} />
    );
  }
}
export default Patients;