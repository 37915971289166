import * as zoid from "zoid/dist/zoid.frameworks";
import React, {ReactDOM} from "react";
var MyLoginZoidComponent = zoid.create({


    // The html tag used to render my component

    tag: 'my-login-component',

    // The url that will be loaded in the iframe or popup, when someone includes my component on their page

    url: new URL('http://localhost:3001/login', window.location.href).href,
    
    dimensions: {
        width: '800px',
        height: '300px'
    },
contexts: {
    popup: true
  },
    // The properties they can (or must) pass down to my component. This is optional.

    props: {

        prefilledEmail: {
            type: 'string',
            required: false
        },

        onLogin: {
            type: 'function',
            required: false
        }
    },
    
});
console.log("component Loaded");
export default MyLoginZoidComponent;