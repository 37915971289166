import { Dialog, DialogTitle, DialogContentText ,Button, TextField, Select, MenuItem, DialogContent, DialogActions} from '@mui/material';
import React, {useState, useEffect} from 'react'
import { getToken } from '../Utils/Common';

const token = getToken();
const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

//in this component we pass parameters required for specifying the record we want to effect and the a label for the button
export default function ToggleNotification ({row, label, title, activate, func1, func2, func3, prevState, editNotes }) {
  let ticketVal;
  let ticketStr;  
  let ticketInt;
  
  if(prevState !=null){
    ticketVal = prevState.split(":");
    ticketInt = ticketVal[0];
    ticketStr = ticketVal[1];   
  }

    const [open, setOpen] = useState(false);
    const [note, setNote] = useState(prevState);
    const [rational, setRational] = useState(ticketStr);
    const [ticketNum, setTicketNum] = useState(ticketInt);
    const [toggle, setToggle] = useState(false);

    const handleChange = (event) => {
      setRational(event.target.value);
      setNote(`${ticketNum}:${event.target.value}`);
    }
    const handleClickOpen = () => {
      console.log("in open");
      setOpen(true);
      setToggle(activate);
    }
    const handleClose = () => {
        setOpen(false);
      console.log("in close");
    }
    const handleActive = () => {
      console.log("in active");
      func1(`${ticketInt}:`, row.id);
      handleClose();
    }

    const handlePaused = () => {
      func2(`${ticketInt}:${rational}`, row.id);
      handleClose();
    }

    const handleEdit = () => {
      console.log("in edit", row);
      let rationalStr = "";
      if(rational != null){
        rationalStr = rational
      }
      func3(`${ticketNum}:${rationalStr}`, row.id, row.alert_status);
      handleClose();
    }

    return (
        <div>
      <Button  refresh = "true" onClick={handleClickOpen} color="secondary" variant ="text">
      {label}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        {editNotes?<DialogContent>
          <DialogContentText>
            Please enter ZenDesk Ticket Number.
          </DialogContentText>
          <TextField
            autoFocus
            id="name"
            label="Zendesk Ticket Number"
            type="note"
            fullWidth
            defaultValue={ticketNum}
            variant="standard"
            onChange={(e) => setTicketNum(e.target.value)}
          />
        </DialogContent>:
        <div>
          {(toggle)?<DialogContent>
          <DialogContentText>
            Please enter the the reason for pausing the alert. 
          </DialogContentText>
          <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Rational"
          onChange={(e) =>handleChange(e)}
        >
          <MenuItem value={"Device Change"}>Device Change</MenuItem>
          <MenuItem value={"Site Request"}>Site Request</MenuItem>
          <MenuItem value={"Poor Cell Coverage"}>Poor Cell Coverage</MenuItem>
          <MenuItem value={"Other"}>Other</MenuItem>
        </Select>
        </DialogContent>:<></>
        }
      </div>}
      
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>

          {(editNotes)?
          <Button onClick={handleEdit}>{title}</Button>
          :<div>

            {toggle?
              <Button onClick={handlePaused}>{title}</Button>
            :
              <Button onClick={handleActive}>{title}</Button>
            }
          </div>}
        </DialogActions>
      </Dialog>
    </div>
    )
}

