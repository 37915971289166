import {Grid, Typography, Dialog, DialogContent} from '@mui/material';
import { default as React, useState } from 'react';

function HandleRejection(props) {
  const [openDialog, setOpenDialog] = useState(props.open);

  const handleClose = () => {
    setOpenDialog(false);
  };
  
  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <DialogContent>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <img
                src={
                  process.env.PUBLIC_URL + "/ctx_logo.png"
                }
                style={{ height: "50px" }}
                alt=""
            />
          </Grid>
          <Grid item>
            <Typography component="h1" variant="h5" align="center">
              No Access Granted
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default HandleRejection;
