import { Chip } from "@mui/material";
import { withTheme } from "@mui/styles";
import React from "react";
import Color from "./Color";

const ColorChip = (props) => (
  <Color color={props.color}>
    <Chip
      {...props}
      color="primary"
      style={{ border: `1px solid ${props.theme.palette[props.color].dark}` }}
    />
  </Color>
);

export default withTheme(ColorChip);
