import React, { useState } from 'react';
import axios from 'axios';
import { getToken, getEditUser, getEditRole, getRole, removeUserSession } from './Utils/Common';
import HandleRejection from './components/RoleRejection';

function User(props) {
  const { params } = props.match;
  const loggedInRole = getRole();
  const id = params.id;
  const token = getToken();
  console.log('params:', params);
  var create = true;
  var inUser = '';
  var inPass = '';
  var inRole = 0;
  if (id !== undefined) {
    create = false;
    inUser = getEditUser();
    inRole = getEditRole();
  }
  const username = useFormInput(inUser);
  const password = useFormInput(inPass);
  const role = useSelectInput(inRole);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);

  const config = {
      headers: { 'Content-type': 'application/json',
                 'Authorization': `Bearer ${token}` }
   };


  // handle button click of user form
  const handleUpdate = () => {
    console.log('in handleUpdate');
    setError(null);
    setUpdating(true);
    if (create) {
      var url = '/api/v1/users';
      var data = { username: username.value, password: password.value, role: role.value };
      axios.post(url, data , config).then(response => {
        console.log('in post response');
        setUpdating(true);
        props.history.push('/users');
      }).catch(error => {
        console.log('error');
        setUpdating(false);
        if (error.response.status === 401) {
          setError(error.response.data.message);
        }
        else if (error.response.status !== 401) {
          removeUserSession();
          window.location.reload();
        }
        else {
          setError("Something went wrong. Please try again later.");
        }
      });
    } else {
      var url = '/api/v1/user/' + id;
      var data = { username: username.value, role: role.value };
      if (password.value !== '') {
        data.password = password.value;
      }
      axios.put(url, data , config).then(response => {
        console.log('in put response');
        setUpdating(true);
        props.history.push('/users');
      }).catch(error => {
        console.log('error');
        setUpdating(false);
        if (error.response.status === 401) {
          setError(error.response.data.message);
        }
        else if (error.response.status !== 401) {
          removeUserSession();
          window.location.reload();
        }
        else {
          setError("Something went wrong. Please try again later.");
        }
      });
    }
  }

  if(loggedInRole !== "6") {
    return (
      <div>
        {create?'Create':'Update'} User Form<br /><br />
        <div>
          Username<br />
          <input type="text" {...username} autoComplete="new-password" />
        </div>
        <div style={{ marginTop: 10 }}>
          Password<br />
          <input type="password" {...password} autoComplete="new-password" />
        </div>
        <div style={{ marginTop: 10 }}>
          Role<br />
          <select name="role" {...role}>
            <option value="0">Admin</option>
            <option value="1">User</option>
          </select>
        </div>
        {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
        <input type="button" value={create ? 'Create' : 'Modify'} onClick={handleUpdate} disabled={updating} /><br />
      </div>
    );
  }
  else {
    return(
      <HandleRejection open={true}/>
    );
  }
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

const useSelectInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default User;
