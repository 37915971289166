import { createTheme, ThemeProvider } from "@mui/material/styles";
import MenuIcon from '@mui/icons-material/Menu';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Link as RouterLink, NavLink, Route, Switch } from 'react-router-dom';
import Dashboard from './Dashboard';
import Device from './Device';
import Devices from './Devices';
import Events from './Events';
import Home from './Home';
import Login from './Login';
import Sessions from './Sessions';
import RawData from './RawData';
import Patients from './Patients';
import Patient from './Patient';
import User from './User';
import Users from './Users';
import AdRules from './adRules';
import WatchData from './WatchData';
import AssignDevice from './AssignDevice';
import Logout from'./components/Logout'
import { getToken, getRole, getAPIVersion, setAPIVersion,getUser, getLName, getFName } from './Utils/Common';
import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';
import {Container} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { nameConstants } from './constants';
import { version as appVersion } from './version';
import UploadToS3WithReactS3 from './components/UploadToS3WithReactS3';
import PrintIcon from '@mui/icons-material/Print';
import VerifyToken from './components/verifyToken';

// Conditional imports
let Serial;
// if(process.env.TARGET == 'desktop'){
//   Serial = require('./Serial')
// }else{
//   Serial = null; // Possibly need a stub
// }
const token = getToken();
const role = getRole();

const config = {
  headers: { Authorization: `Bearer ${token}` }
 };

const theme_gray = { 
  light: "#5e646b",
  main: "#343a40",
  dark: "#0e141a",
  contrastText: "#FFFFFF",
};

const theme_blue = {
  light: "#5fd9fc",
  main: "#06a7c9",
  dark: "#007898",
  contrastText: "#000000",
}
let adherenceAccess = (role === "0" || role === "1" || role === "2");
let usersAccess = (role === "0");
let devicesAccess = (role === "0" || role === "3");
let participantAccess = (role === "0" || role === "1" ||role === "2" || role === "3");
let assignDeviceAccess = (role === "0" || role === "3");

const routes = {
  "/Home": { title: "Home", component: Home, exact: true, nav: true, public: true },
  "/login": { title: "Login", component: Login, public: true, nav: !token },
  "/dashboard": { title: "Adherence", component: Dashboard, nav: adherenceAccess},
  "/users": { title: "Users", component: Users, nav: usersAccess},
  "/devices": { title: "Devices", component: Devices, nav: devicesAccess },
  "/participants": { title: nameConstants.Patient + "s", component: Patients, nav: participantAccess },
  "/assigndevice": { title: "Assign Device", component: AssignDevice, nav: assignDeviceAccess },
  "/events/:unit_id&:event_type&:start_date&:end_date&:assignment": { title: "Workouts", component: Events },
  "/user/:id": { title: "UserID", component: User },
  "/user": { title: "User", component: User },
  "/device/:unit_id": { title: "DeviceID", component: Device },
  "/device": { title: "Device", component: Device },
  "/adRules": { title: "AdRules", component: AdRules },
  "/sessions/:unit_id&:session_type&:start_date&:end_date&:assignment": { title: "Workouts", component: Sessions },
  "/participant/:participant_id": {title: nameConstants.Patient , component: Patient},
  "/watchdata/:device_id": { title: "Workouts", component: WatchData },
  "/reportdata": { title: "Patients", component: Patients },
  //"/upload": { title: "Uploads3", component: UploadToS3WithReactS3, exact: true, public: true},

};

if(process.env.TARGET == 'desktop'){
  routes["/serial"] = {title:"Serial", component: Serial, nav: true, public:true};
}

const theme = createTheme({
  props: {
    // Name of the component
    MuiButton: {
      // The properties to apply
      //variant: 'contained'
    },
  },
  palette: {
    primary: theme_gray,
    secondary: theme_blue,
  },
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
      marginTop: "10px",
    },
    h3: {
      fontSize: "1.75rem",
    },
    h4: {
      fontSize: "1.6rem",
    },
  },
});

const useStyles = makeStyles(() => ({
  appBar: {
    top: "auto",
    bottom: 0
  },
  toolbar: theme.mixins.toolbar,
}));

function App() {
  const [authLoading, setAuthLoading] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const classes = useStyles();
  const apiVersion = getAPIVersion();
  const [dateState, setDateState] = useState(new Date());
  const [today, setToday] = useState(new Date());
  const [ users, setUsers ] = useState([]);
  const [ count, setCount ] = useState(0);
  const [ fName, setFName ] = useState(getFName());
  const [ lName, setLName ] = useState(getLName());


  useEffect(() => {
           setInterval(() => setDateState(new Date()), 30000);
    }, []);

  useEffect(() => {
    setAPIVersion()
  }, []);
  
  useEffect(() => {
    setToday(new Date());
    setInterval(() => VerifyToken(), 900000);
  }, [token, role]);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
      
        <AppBar position="static">
          <Toolbar>
            {/* on smaller screens show page title and a menu for nav */}
            <Box
              display={{ xs: "inherit", md: "none" }}
              sx={{ alignItems: "center" }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  setNavOpen(true);
                }}
              >
                <MenuIcon />
              </IconButton>
              <img
                src={
                  process.env.PUBLIC_URL + "/ctx_logo.png"
                }
                style={{ height: "36px" }}
                alt=""
              />
              &nbsp; &nbsp;
              <Typography variant="h6">
                {/* { adminRoutes[this.props.location.pathname].title } */}
              </Typography>
              <Drawer
                anchor="left"
                open={navOpen}
                onClose={() => {
                  setNavOpen(false);
                }}
              >
                <List
                  onClick={() => {
                    setNavOpen(false);
                  }}
                  onKeyDown={() => {
                    setNavOpen(false);
                  }}
                >
                  {Object.entries(routes).map(([path, val]) => {
                    return (
                      val.nav && <ListItem
                        button
                        key={path}
                        component={RouterLink}
                        to={path}
                      >
                        <ListItemText primary={val.title} />
                      </ListItem>
                    );
                  })}
                  <ListItem>
                    {token ?<Logout/>  : <div></div> }
                    </ListItem>
                  <ListItem>
                    <ListItemText>Web Version: {appVersion}:</ListItemText>
                    
                    <ListItemText>API Version: {apiVersion}</ListItemText>
                    
                  </ListItem>
                </List>
              </Drawer>
            </Box>

            {/* show links in header on larger screens */}
            <Box
              display={{ xs: "none", md: "inherit" }}
              sx={{ alignItems: "center" }}
            >
              <img
                src={process.env.PUBLIC_URL + "/cognito-full-logo.png"}
                style={{ height: "50px" }}
                alt=""
              />
               
              &nbsp; &nbsp;
              {Object.entries(routes).map(([path, val]) => {
                return (
                  val.nav && <Button
                    color="inherit"
                    component={RouterLink}
                    to={path}
                    key={path}
                  >
                    {val.title}
                  </Button>
                );
              })}
              {token ?<Logout/>  : <div></div> }
            </Box>

            <div style={{ flexGrow: 1 }} />
            <Typography> Web Version: {appVersion} </Typography>
            &nbsp; | &nbsp;
            <Typography> API Version: {apiVersion} </Typography>
            <IconButton color = 'secondary' onClick={window.print}><PrintIcon/></IconButton>
            
          </Toolbar>
        </AppBar>
        <Container className="flex-grow-1 mt-5" sx={{ marginTop: "10px" }}>
          <Switch>
            {Object.entries(routes).map(([path, val]) => {
              let RouteComponent = val.public ? PublicRoute : PrivateRoute;
              return (
                <RouteComponent
                  path={path}
                  component={val.component}
                  exact={val.exact}
                  key={path}
                />
              );
            })}
          </Switch>
        </Container>
      </BrowserRouter>
      <div className={classes.toolbar}>
        <AppBar sx={{ background: 'transparent', boxShadow: 'none', color: "black", "z-index": "-1", top:'auto', bottom:'0'}} className={classes.appBar}>
          <Toolbar>
            <Grid>
              <Typography variant="h6" sx={{ color: theme_blue.dark }}>
                <b>{`${fName} ${lName}, ${getUser()}`}</b>
              </Typography>
            </Grid>
            <div style={{ flexGrow: 1 }} />
            <Grid>
              <img
                src={
                  process.env.PUBLIC_URL + "/right-waves.svg"
                }
                style={{ height: "150px" }}
                alt=""
              />
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    </ThemeProvider>
  );
}

export default App;
