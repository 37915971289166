import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { getToken, getRole, removeUserSession, getStudies } from './Utils/Common';
import { Alert, Accordion, Button, Grid, Typography, AccordionSummary, TextField, Box, InputLabel, MenuItem, FormControl, Select, CircularProgress, Backdrop, AccordionDetails } from '@mui/material';
import { nameConstants } from './constants';
import HandleRejection from './components/RoleRejection';
import EditIcon from '@mui/icons-material/Edit';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ToggleNotification from './components/PlayPauseDialog';
import ReportGenerator from './components/ReportRequest';

function Dashboard() {
  const role = getRole();
  const studyAdminStudies = getStudies();
  const token = getToken();
  const [alerts, setAlerts] = useState([]);
  const [alertCount, setAlertCount] = useState(0);
  const [pastAlerts, setPastAlerts] = useState([]);
  const [pastCount, setPastCount] = useState([]);
  const [clearedAlerts, setClearedAlerts] = useState([]);
  const [clearedAlertCount, setClearedCount] = useState(0);
  const [showBannerAlert, setShowBannerAlert] = useState(true);
  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(0);
  const [page2, setPage2] = useState(0);
  const [filter, setFilter] = useState('');
  const [refresh, setRefresh] = useState(0);
  const [filterKey, setFilterKey] = useState("");
  const [filterStr, setFilterStr] = useState("");
  const [loading, setLoading] = useState(false);
  const [studiesList, setStudiesList] = useState([])
  const [validStages, setValidStages] = useState([])
  const [lastPage, setLastPage] = useState(0)
  const now = new Date();
  const checkDate = now.setDate(now.getDate() - 3);

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  const dashColors = ["grey", "red", "grey"];

  // get devices on each rerender one call for actions 
  useEffect(() => {
    setFilterStr(filterKey)
  }, [filterKey]);

  useEffect(() => {
    if (role !== "3") {
      if (filterStr !== "") {
        console.log("FilterStrFlag", filterStr)
        handleFilterRequest();
      } else { getAlertList(); }
      console.log("refreshcount: ", refresh);
    }
  }, [refresh]);

  useEffect(() => {
    getAllStudies();
  }, []);

  const handleDateParse = (dateStr) => {
    let year = dateStr.substring(0, 4);
    let month = dateStr.substring(5, 7);
    let day = dateStr.substring(8, 10);
    let dateRes = `${month}/${day}/${year}`;
    return dateRes;
  }

  const handleActiveRequest = (note, row) => {
    let newNote = note.replaceAll(":", "");
    let notificationurl = "/api/v1/notification/" + row
    let data = { notes: newNote, status: "active" }
    axios.put(notificationurl, data, config).then(response => {
      console.log('in active put response');
      console.log(response);
    }).catch(e => {
      console.log('error');
      console.log(e);
      if (e.response.status !== 404) {
        removeUserSession();
        window.location.reload();
      }
    });
    setTimeout(() => setRefresh(refresh + 1), 1000);
  }

  const handlePauseRequest = (note, row) => {
    console.log("PausedRow: ", row);
    let notificationurl = "/api/v1/notification/" + row;
    let data = { notes: note, status: "paused" };
    axios.put(notificationurl, data, config).then(response => {
      console.log('in pause put response');
      console.log(response);
    }).catch(e => {
      console.log('error');
      console.log(e);
      if (e.response.status !== 404) {
        removeUserSession();
        window.location.reload();
      }
    });
    setTimeout(() => setRefresh(refresh + 1), 1000);
  }

  const handleEditRequest = (note, row, statusStr) => {
    console.log("EditRow: ", row, statusStr)
    let notificationurl = "/api/v1/notification/" + row;
    let data = { notes: note, status: statusStr };
    axios.put(notificationurl, data, config).then(response => {
      console.log('in edit put response');
      //console.log(response);
    }).catch(e => {
      console.log('error');
      console.log(e);
      if (e.response.status !== 404) {
        removeUserSession();
        window.location.reload();
      }
    });
    console.log(refresh);
    setTimeout(() => setRefresh(refresh + 1), 1000);
  }

  const handleFilterRequest = async () => {
    let filterUrlA = "";
    let filterUrlP = "";
    let filterUrlC = "";
    let filterUrl = "";
    console.log(filter, 'fil')
    switch (filter) {
      case 2:
        filterUrlA = `/api/v1/notifications?status=active&site=${filterKey}`
        filterUrlP = `/api/v1/notifications?status=paused&site=${filterKey}`
        filterUrlC = `/api/v1/notifications?&site=${filterKey}`
        const responseSIC = await axios.get(filterUrlC, config).catch(e => {
          console.log('error');
          console.log(e);
          if (e.response.status !== 404) {
            removeUserSession();
            window.location.reload();
          }
        });
        setPastCount(responseSIC.data.count);
        const responsePIC = await axios.get(filterUrlP, config).catch(e => {
          console.log('error');
          console.log(e);
          if (e.response.status !== 404) {
            removeUserSession();
            window.location.reload();
          }
        });
        setClearedCount(responsePIC.data.count);
        const responseAIC = await axios.get(filterUrlA, config).catch(e => {
          console.log('error');
          console.log(e);
          if (e.response.status !== 404) {
            removeUserSession();
            window.location.reload();
          }
        });
        setAlertCount(responseAIC.data.count);
        //console.log(responseA, responseP, responseC)

        parseAlertLists(responseSIC, [], [], [], 2);
        parseAlertLists(responsePIC, [], [], [], 1);
        parseAlertLists(responseAIC, [], [], [], 0);
        break;
      case 3:
        filterUrlA = `/api/v1/notifications?status=active&study=${filterKey}`
        filterUrlP = `/api/v1/notifications?status=paused&study=${filterKey}`
        filterUrlC = `/api/v1/notifications?study=${filterKey}`
        console.log('in here')
        const responseCIS = await axios.get(filterUrlC, config).catch(e => {
          console.log('error');
          console.log(e);
          if (e.response.status !== 404) {
            removeUserSession();
            window.location.reload();
          }
        });
        setPastCount(responseCIS.data.count);
        const responsePIS = await axios.get(filterUrlP, config).catch(e => {
          console.log('error');
          console.log(e);
          if (e.response.status !== 404) {
            removeUserSession();
            window.location.reload();
          }
        });
        setClearedCount(responsePIS.data.count);
        const responseAIS = await axios.get(filterUrlA, config).catch(e => {
          console.log('error');
          console.log(e);
          if (e.response.status !== 404) {
            removeUserSession();
            window.location.reload();
          }
        });
        setAlertCount(responseAIS.data.count);
        //console.log(responseAIS, responsePIS, responseC)

        parseAlertLists(responseCIS, [], [], [], 2);
        parseAlertLists(responsePIS, [], [], [], 1);
        parseAlertLists(responseAIS, [], [], [], 0);
        break;
      case 4:
        filterUrlA = `/api/v1/notifications?status=active&stage=${filterKey}`
        filterUrlP = `/api/v1/notifications?status=paused&stage=${filterKey}`
        filterUrlC = `/api/v1/notifications?&stage=${filterKey}`

        const responseC = await axios.get(filterUrlC, config).catch(e => {
          console.log('error');
          console.log(e);
          if (e.response.status !== 404) {
            removeUserSession();
            window.location.reload();
          }
        });
        setPastCount(responseC.data.count);
        const responseP = await axios.get(filterUrlP, config).catch(e => {
          console.log('error');
          console.log(e);
          if (e.response.status !== 404) {
            removeUserSession();
            window.location.reload();
          }
        });
        setClearedCount(responseP.data.count);
        const responseA = await axios.get(filterUrlA, config).catch(e => {
          console.log('error');
          console.log(e);
          if (e.response.status !== 404) {
            removeUserSession();
            window.location.reload();
          }
        });
        setAlertCount(responseA.data.count);
        //console.log(responseA, responseP, responseC)

        parseAlertLists(responseC, [], [], [], 2);
        parseAlertLists(responseP, [], [], [], 1);
        parseAlertLists(responseA, [], [], [], 0);
        break;
      case 5:
        filterUrl = `/api/v1/notifications?&status=${filterKey}`
        const response = await axios.get(filterUrl, config).catch(e => {
          console.log('error');
          console.log(e);
          if (e.response.status !== 404) {
            removeUserSession();
            window.location.reload();
          }
        });
        setAlertCount(response.data.count);
        if (filterKey === "active") {
          setAlertCount(response.data.count);
          setClearedCount(0);
          setPastCount(0);
          parseAlertLists(response, [], [], [], 0);
        }
        else if (filterKey === "paused") {
          setPastCount(0);
          setAlertCount(0);
          setClearedCount(response.data.count);
          setAlerts([]);
          setClearedAlerts([]);
          parseAlertLists(response, [], [], [], 1);
        }
        else {
          setPastCount(response.data.count);
          setClearedCount(0);
          setAlertCount(0);
          parseAlertLists(response, [], [], [], 2);
        }
        break;
      default:
        filterUrlC = `/api/v1/notifications?subject=${filterKey}`
        filterUrlP = `/api/v1/notifications?status=paused&subject=${filterKey}`
        filterUrlA = `/api/v1/notifications?status=active&subject=${filterKey}`

        const resA = await axios.get(filterUrlA, config).catch(e => {
          console.log('error');
          console.log(e);
          if (e.response.status !== 404) {
            removeUserSession();
            window.location.reload();
          }
        });
        setAlertCount(resA.data.count);
        const resP = await axios.get(filterUrlP, config).catch(e => {
          console.log('error');
          console.log(e);
          if (e.response.status !== 404) {
            removeUserSession();
            window.location.reload();
          }
        });
        setClearedCount(resP.data.count);
        const resC = await axios.get(filterUrlC, config).catch(e => {
          console.log('error');
          console.log(e);
          if (e.response.status !== 404) {
            removeUserSession();
            window.location.reload();
          }
        });
        setPastCount(resC.data.count);

        console.log(resA, resP, resC)
        parseAlertLists(resA, [], [], [], 0);
        parseAlertLists(resP, [], [], [], 1);
        parseAlertLists(resC, [], [], [], 2);
        break;
    }
    console.log("InhandleFilter", filter, filterUrl);

  }

  const getAllStudies = async () => {
    let url = `/api/v1/studies`;
    let studies = [];
    let validStages = [];
    if (role == 0) {
      await axios.get(url, config).then((resp) => {
        resp.data.studies.map((study) => {
          if (!studies.includes(study.study_id)) {
            studies.push(study.study_id);
          }
          if (study.study_attributes && study.study_attributes.valid_stage_pairs) {
            study.study_attributes.valid_stages.map(stage => {
              if (!validStages.includes(stage)) {
                validStages.push(stage);
              }
            })
          }
        });
        return resp;
      }).catch((e) => {
        console.log('error');
        console.log(e);
        if (e.status !== 404) {
          removeUserSession();
          window.location.reload();
        }
      });
    }
    if (role == 2 && studyAdminStudies) {
      let studiesArr = studyAdminStudies.split(',');
      studiesArr.map(async (study) => {
        const studyStagesUrl = `api/v1/study/${study}`;
        await axios.get(studyStagesUrl, config).then((resp) => {
          resp.data.study_attributes.valid_stages.map(stage => {
            if (!validStages.includes(stage))
              validStages.push(stage)
          });
          return resp;
        }).catch((e) => {
          console.log('error');
          console.log(e);
          if (e.status !== 404) {
            removeUserSession();
            window.location.reload();
          }
        });
      })
    }
    setStudiesList(studies);
    validStages.splice(0, 1);
    setValidStages(validStages);
  }

  function getDeviceType(typeNumber) {
    switch (typeNumber) {
      case 0:
        return "GS120";
      case 1:
        return "Actigraph";
      case 2:
        return "iPAD";
      default:
        return typeNumber;
    }
  }

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  const parseAlertLists = async (response, alertLog, clearLog, pastLog, type) => {
    var parseAlert;
    for (let i = 0; i < response.data.notifications.length; i++) {
      let notificationRow = response.data.notifications[i];
      let deviceType = notificationRow.device_type;
      let ID = notificationRow.id;
      let regID = notificationRow.registration_id;
      let subjectID = notificationRow.subject_id;
      let alertType = notificationRow.notification_type;
      let dateVar = notificationRow.notification_date;
      let alertStatus = notificationRow.status || notificationRow.alert_status;
      let notes = notificationRow.notes;
      let study_stage = notificationRow.registration_status;
      let alert_type = notificationRow.notification_type;
      let rule_broken;

      //rule broken can either be "System Alert" or "Adherence Alert"
      if (alert_type === 0) {
        rule_broken = "System Alert";
      }
      else {
        rule_broken = notificationRow.content.category;
      }

      parseAlert = {
        "id": ID, "registration_id": regID, "subject_id": subjectID, "device_type": getDeviceType(deviceType), "alert_type": getAlertType(alertType),
        "alert_triggered": dateVar, "alert_status": alertStatus, "notes": notes, "study_stage": study_stage, "alert_type": rule_broken
      }

      if (alertStatus == "active") {
        if (alertLog.length == 20) { return }
        alertLog.push(parseAlert);
      }
      else if (alertStatus == "paused") {
        if (clearLog.length == 20) { return }
        clearLog.push(parseAlert);
      }
      else {
        pastLog.push(parseAlert);
      }
    }
    if (response.status === 404) {
      setAlerts([]);
      setClearedAlerts([]);
      setPastAlerts([]);
      setAlertCount(0);
      setPastCount(0);
      setClearedCount(0);
    } else {
      console.log(alertLog);
      console.log(clearLog);
      if (type === 0) {
        setAlerts(alertLog);
      }
      else if (type === 1) {
        setClearedAlerts(clearLog);
      }
      else if (type === 2) {
        setPastAlerts(pastLog);
      }
    }
  }

  const getAlertList = async (next_page, next_page2, next_page3, filterState) => {
    try {
      if (!next_page) {
        next_page = 1;
      }
      else if (next_page >= 1) {
        next_page = next_page + 1
      }
      if (!next_page2) {
        next_page2 = 1;
      }
      else if (next_page2 >= 1) {
        next_page2 = next_page2 + 1
      }
      if (!next_page3) {
        next_page3 = 1;
      }
      else if (next_page3 >= 1) {
        next_page3 = next_page3 + 1
      }
      const patientsUrl_a = '/api/v1/notifications?status=active&page=' + next_page;
      const patientsUrl_p = '/api/v1/notifications?status=paused&page=' + next_page2;
      const patientsUrl_o = '/api/v1/notifications?status=past&page=' + next_page3;

      const response_o = await axios.get(patientsUrl_o, config).catch(e => {
        console.log('error');
        console.log(e);
        if (e.response.status !== 404) {
          removeUserSession();
          window.location.reload();
        }
      });
      setPastCount(response_o.data.count);
      //console.log(response_a);
      const response_a = await axios.get(patientsUrl_a, config).catch(e => {
        console.log('error');
        console.log(e);
        if (e.response.status !== 404) {
          removeUserSession();
          window.location.reload();
        }
      });
      setAlertCount(response_a.data.count);
      setLastPage(response_a.data.last_page);
      const response_p = await axios.get(patientsUrl_p, config).catch(e => {
        console.log('error');
        console.log(e);
        if (e.response.status !== 404) {
          removeUserSession();
          window.location.reload();
        }
      });
      setClearedCount(response_p.data.count);

      parseAlertLists(response_o, [], [], [], 2);
      parseAlertLists(response_a, [], [], [], 0);
      parseAlertLists(response_p, [], [], [], 1);
    }
    catch (error) {
      console.log(error)
    }
  }

  function getAlertType(typeNumber) {
    switch (typeNumber) {
      case 0:
        return "Device Error";
      case 1:
        return "Adherence";
      case 2:
        return "Other";
      default:
        return typeNumber;
    }
  }

  function convertToCSV(arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr[i].length; j++) {
        arr[i][j] = (arr[i][j]).replace(/,/g, "");
      }
    }
    const array = [Object.keys(arr[0])].concat(arr);
    return array.map(it => {
      return Object.values(it).toString();
    }).join('\n');
  }

  const downloadFile = async () => {
    setLoading(true);
    console.log("downloadFile");
    let parseAlert;
    let allActiveAlerts = [];
    for (let i = 1; i <= lastPage; i++) {
      const patientsUrl_a = '/api/v1/notifications?status=active&page=' + i;
      const response = await axios.get(patientsUrl_a, config).catch(e => {
        console.log('error');
        console.log(e);
        if (e.response.status !== 404) {
          removeUserSession();
          window.location.reload();
        }
      });
      response.data.notifications.map((rawData) => {
        let deviceType = rawData.device_type;
        let ID = rawData.id;
        let regID = rawData.registration_id;
        let subjectID = rawData.subject_id;
        let alertType = rawData.notification_type;
        let alertDate = rawData.notification_date;
        let alertStatus = rawData.status || rawData.alert_status;
        let notes = rawData.notes;
        let study_stage = rawData.registration_status;
        let alert_type = rawData.notification_type;
        let rule_broken;

        //rule broken can either be "System Alert" or "Adherence Alert"
        if (alert_type === 0) {
          rule_broken = "System Alert";
        }
        else {
          rule_broken = rawData.content.category;
        }

        parseAlert = {
          "id": ID, "registration_id": regID, "subject_id": subjectID, "device_type": getDeviceType(deviceType), "alert_type": getAlertType(alertType),
          "alert_triggered": alertDate, "alert_status": alertStatus, "notes": notes, "study_stage": study_stage, "alert_type": rule_broken
        }
        allActiveAlerts.push(parseAlert);
      })
    }
    const dataStr = convertToCSV(allActiveAlerts);
    console.log(allActiveAlerts);
    let fileName = `Adherence Report-_All Active Alerts`;

    const blob = new Blob([dataStr], { type: 'text/csv' });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    a.dispatchEvent(clickEvt);
    a.remove();
    setLoading(false);
  }

  const alertcolumns = [
    {
      field: 'subject_id',
      headerName: nameConstants.Patient + ' ID',
      width: 160,
      sortable: true,
      editable: false,
      renderCell: (params) => {
        let fmtDate = (new Date(params.row.alert_triggered)).getTime();
        let fontColor = dashColors[0];
        if (fmtDate < checkDate) {
          fontColor = dashColors[1];
        }
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'study_stage',
      headerName: 'Study Stage',
      width: 130,
      sortable: true,
      editable: false,
      renderCell: (params) => {
        let fmtDate = (new Date(params.row.alert_triggered)).getTime();
        let fontColor = dashColors[0];
        if (fmtDate < checkDate) {
          fontColor = dashColors[1];
        }
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'device_type',
      headerName: 'Device',
      width: 100,
      sortable: true,
      editable: false,
      renderCell: (params) => {
        let fmtDate = (new Date(params.row.alert_triggered)).getTime();
        let fontColor = dashColors[0];
        if (fmtDate < checkDate) {
          fontColor = dashColors[1];
        }
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'alert_triggered',
      headerName: 'Alert Triggered',
      width: 150,
      sortable: true,
      editable: false,
      renderCell: (params) => {
        let fmtDate = handleDateParse(params.row.alert_triggered);
        let fontColor = dashColors[0];
        if (fmtDate < checkDate) {
          fontColor = dashColors[1];
        }
        return (
          <div style={{ color: fontColor }}> {fmtDate} </div>
        );
      }
    },
    {
      field: 'alert_type', // triggered note
      headerName: 'Alert Type',
      width: 160,
      sortable: true,
      editable: true,
      renderCell: (params) => {
        let fmtDate = (new Date(params.row.alert_triggered)).getTime();
        let fontColor = dashColors[0];
        if (fmtDate < checkDate) {
          fontColor = dashColors[1];
        }
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'notes', // triggered note
      headerName: 'Ticket Number',
      width: 160,
      sortable: false,
      editable: true,
      renderCell: (params) => {
        let fmtDate = (new Date(params.row.alert_triggered)).getTime();
        let fontColor = dashColors[0];
        if (fmtDate < checkDate) {
          fontColor = dashColors[1];
        }
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'acknowledge_alert', // triggered note
      headerName: 'Action',
      width: 250,
      sortable: false,
      editable: false,
      renderCell: (params) =>
        <div>
          <Button title="View Patient Details"
            href={'/' + nameConstants.Patient + '/' + params.row.subject_id}
            color="secondary"
          >
            <ManageAccountsIcon />
          </Button>
          <Button title="Edit Ticket Number" >
            <ToggleNotification
              prevState={params.row.notes}
              row={params.row}
              label={<EditIcon />}
              editNotes={true}
              activate={false}
              title="Save Ticket Number"
              func1={handleActiveRequest}
              func2={handlePauseRequest}
              func3={handleEditRequest} />
          </Button>
          <Button title="Pause Notification">
            <ToggleNotification
              prevState={params.row.notes}
              row={params.row}
              label={<PauseIcon />}
              title="Pause Notification"
              activate={true}
              func1={handleActiveRequest}
              func2={handlePauseRequest}
              func3={handleEditRequest} />
          </Button>
        </div>,
    },
  ];

  const clearedAlertcolumns = [
    {
      field: 'subject_id',
      headerName: nameConstants.Patient + ' ID',
      width: 160,
      sortable: true,
      editable: false,
      renderCell: (params) => {
        let fontColor = dashColors[2];
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'study_stage',
      headerName: 'Study Stage',
      width: 130,
      sortable: true,
      editable: false,
      renderCell: (params) => {
        let fmtDate = (new Date(params.row.alert_triggered)).getTime();
        let fontColor = dashColors[0];
        if (fmtDate < checkDate) {
          fontColor = dashColors[2];
        }
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'alert_status',
      headerName: 'Alert Status',
      width: 130,
      sortable: true,
      editable: false,

    },
    {
      field: 'device_type',
      headerName: 'Device',
      width: 100,
      sortable: true,
      editable: false,
      renderCell: (params) => {
        let fontColor = dashColors[2];
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'alert_triggered',
      headerName: 'Alert Triggered',
      width: 150,
      sortable: true,
      editable: false,
      renderCell: (params) => {
        let fontColor = dashColors[2];
        let fmtDate = handleDateParse(params.value);

        return (
          <div style={{ color: fontColor }}> {fmtDate} </div>
        );
      }
    },
    {
      field: 'alert_type', // triggered note
      headerName: 'Alert Type',
      width: 160,
      sortable: true,
      editable: true,
      renderCell: (params) => {
        let fontColor = dashColors[2];
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'notes', // triggered note
      headerName: 'Ticket Number',
      width: 160,
      sortable: false,
      editable: true,
      renderCell: (params) => {
        let fontColor = dashColors[2];
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'acknowledge_alert', // triggered note
      headerName: 'Action',
      width: 250,
      sortable: false,
      editable: false,
      renderCell: (params) =>
        <div>
          <Button title="View Patient Details"
            href={'/' + nameConstants.Patient + '/' + params.row.subject_id}
            color="secondary"
          >
            <ManageAccountsIcon />
          </Button>
          <Button title="Edit Ticket Number" >
            <ToggleNotification
              prevState={params.row.notes}
              row={params.row}
              label={<EditIcon />}
              editNotes={true}
              activate={false}
              title="Save Ticket Number"
              func1={handleActiveRequest}
              func2={handlePauseRequest}
              func3={handleEditRequest} />
          </Button>
          <Button title="Activate Notification">
            <ToggleNotification
              label={<PlayArrowIcon />}
              prevState={params.row.notes}
              row={params.row}
              title="Activate"
              activate={false}
              func1={handleActiveRequest}
              func2={handlePauseRequest}
              func3={handleEditRequest} />
          </Button>
        </div>,
    },
  ];

  const otherAlertcolumns = [
    {
      field: 'subject_id',
      headerName: nameConstants.Patient + ' ID',
      width: 160,
      sortable: true,
      editable: false,
      renderCell: (params) => {
        let fontColor = dashColors[2];
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'study_stage',
      headerName: 'Study Stage',
      width: 130,
      sortable: true,
      editable: false,
      renderCell: (params) => {
        let fmtDate = (new Date(params.row.alert_triggered)).getTime();
        let fontColor = dashColors[0];
        if (fmtDate < checkDate) {
          fontColor = dashColors[2];
        }
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'alert_status',
      headerName: 'Alert Status',
      width: 130,
      sortable: true,
      editable: false,

    },
    {
      field: 'device_type',
      headerName: 'Device',
      width: 100,
      sortable: true,
      editable: false,
      renderCell: (params) => {
        let fontColor = dashColors[2];
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'alert_triggered',
      headerName: 'Alert Triggered',
      width: 150,
      sortable: true,
      editable: false,
      renderCell: (params) => {
        let fontColor = dashColors[2];
        let fmtDate = handleDateParse(params.value);

        return (
          <div style={{ color: fontColor }}> {fmtDate} </div>
        );
      }
    },
    {
      field: 'alert_type', // triggered note
      headerName: 'Alert Type',
      width: 160,
      sortable: true,
      editable: true,
      renderCell: (params) => {
        let fontColor = dashColors[2];
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'notes', // triggered note
      headerName: 'Ticket Number',
      width: 160,
      sortable: false,
      editable: true,
      renderCell: (params) => {
        let fontColor = dashColors[2];
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'acknowledge_alert', // triggered note
      headerName: 'Action',
      width: 190,
      sortable: false,
      editable: false,
      renderCell: (params) =>
        <div>
          <Button title="View Patient Details"
            href={'/' + nameConstants.Patient + '/' + params.row.subject_id}
            color="secondary"
          >
            <ManageAccountsIcon />
          </Button>
          <Button title="Edit Ticket Number" >
            <ToggleNotification
              prevState={params.row.notes}
              row={params.row}
              label={<EditIcon />}
              editNotes={true}
              activate={false}
              title="Save Ticket Number"
              func1={handleActiveRequest}
              func2={handlePauseRequest}
              func3={handleEditRequest} />
          </Button>
        </div>,
    },
  ];

  console.log(filter, filterKey, 'list')
  if (role === "0" || role === "2") {
    return (
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {true && (
          <div style={{ width: "100%" }}>
            {true && (
              <div>
                <Grid container justifyContent='center'>
                  <Grid item >
                    <Button href='/adRules' color="secondary" variant="text" >{'Check Adherence Rules'}</Button>
                  </Grid>
                  <Grid item >
                    <Button color="secondary" variant="text" onClick={() => downloadFile()}>{'Download All Active Alerts'}</Button>
                  </Grid>
                  {(role === "0") ?
                    <Grid item >
                      <ReportGenerator
                        urlName={`/api/v1/report/adherence`}
                        fileName={`Adherence Report-`}
                        fileDetail="All Adherence Data"
                        windowTitle="Download Adherence Report"
                        windowTitlePrimary="Adherence Report"
                        windowTitleSecondary={`Are you sure you want to download the Adherence report?`}
                        btn1Text="Download Adherence Report"
                        btn2Text="All Adherence Data"
                        btn3Text="Download by Date"
                        buttonTitle="Download Adherence Report"
                        dateLabel1={true}
                        dateLabel2={true}
                      />
                    </Grid>
                    :
                    <></>
                  }
                </Grid>
                <br />
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography color="secondary"> Search Table</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ maxWidth: 200 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filter}
                          label="Filter"
                          onChange={handleChange}
                        >
                          <MenuItem value={1}>Participant ID</MenuItem>
                          <MenuItem value={2}>Site</MenuItem>
                          <MenuItem value={4}>Participant Stage</MenuItem>
                          {role == 0 && (
                            <MenuItem value={3}>Study</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                    <br />
                    {(filter == 1 || filter == 2) && (
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Filter Key"
                        type="note"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setFilterKey(e.target.value)}
                      />
                    )}
                    {filter == 4 && (
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" label='Filter Key'>Filter Key</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterKey}
                          label="Filter Key"
                          onChange={(e) => setFilterKey(e.target.value)}
                        >
                          {validStages.map((stage) => (
                            <MenuItem value={stage}>{stage}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {filter == 3 && (
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" label='Filter Key'>Filter Key</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterKey}
                          label="Filter Key"
                          onChange={(e) => setFilterKey(e.target.value)}
                        >
                          {studiesList.map((study) => (
                            <MenuItem value={study}>{study}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    <Button color="secondary" variant="text" onClick={handleFilterRequest}>
                      Search
                    </Button>
                  </AccordionDetails>
                </Accordion>
                <br />
                <div style={{ width: "100%" }}>
                  Active Alerts (Total: {alertCount}):
                </div>
                {(alertCount > 0) ?
                  <div style={{ background: "white" }}>
                    <DataGrid
                      color="secondary"
                      rows={alerts}
                      columns={alertcolumns}
                      autoHeight={true}
                      loading={alerts.length === 0}
                      rowHeight={38}
                      rowCount={alertCount}
                      disableSelectionOnClick
                      pagination
                      paginationMode="server"
                      onPaginationModelChange={(newPage) => { setPage(newPage.page); getAlertList(newPage.page, page1, page2); }}
                      paginationModel={{ page: page, pageSize: 20 }}
                    />
                  </div>
                  :
                  <></>
                }
              </div>
            )}
          </div>
        )}
        <br />
        {true && (
          <div style={{ width: "100%" }}>
            Paused Alerts (Total: {clearedAlertCount}):
            <br />
            {clearedAlertCount > 0 && (
              <div>
                <div style={{ background: "white" }}>
                  <DataGrid
                    color="secondary"
                    rows={clearedAlerts}
                    columns={clearedAlertcolumns}
                    autoHeight={true}
                    loading={clearedAlerts.length === 0}
                    rowHeight={38}
                    rowCount={clearedAlertCount}
                    pagination
                    paginationMode="server"
                    onPaginationModelChange={(newPage) => { setPage1(newPage.page); getAlertList(page, newPage.page, page2); }}
                    paginationModel={{ page: page1, pageSize: 20 }}
                  />
                </div>
              </div>
            )}
            <br />
            Past Alerts (Total: {pastCount}):
            <br />
            {pastCount > 0 && (
              <div>
                <div style={{ background: "white" }}>
                  <DataGrid
                    color="secondary"
                    rows={pastAlerts}
                    columns={otherAlertcolumns}
                    autoHeight={true}
                    loading={pastAlerts.length === 0}
                    rowHeight={38}
                    rowCount={pastCount}
                    pagination
                    paginationMode="server"
                    onPaginationModelChange={(newPage) => { setPage2(newPage.page); getAlertList(page, page2, newPage.page); }}
                    paginationModel={{ page: page2, pageSize: 20 }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <br />
        {(alertCount > 0 && showBannerAlert) && <Alert onClose={() => { setShowBannerAlert(false) }} children={"Alert: You have " + alertCount + " notifications to review"} severity={'error'} />}
      </div>
    );
  }
  else {
    return (
      <HandleRejection open={true} />
    );
  }
}

export default Dashboard;