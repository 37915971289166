import { DialogContent, DialogActions, Dialog, DialogTitle, DialogContentText, Button, TextField } from '@mui/material';
import React, {useState} from 'react';
import axios from 'axios';
import { getToken, removeUserSession } from '../Utils/Common';

import Autocomplete from '@mui/material/Autocomplete';
  const token = getToken();

  const config = {
      headers: { 'Content-type': 'application/json',
                 'Authorization': `Bearer ${token}` }
   };
  const DeviceOptions = [
      { label: 'Watch', id: 1 },
      { label: 'iPad', id: 2 },
      { label: 'GS120', id: 3 },
    ];
    const RuleOptions = [
      { label: 'Therapy Adherence', id: 1 },
      { label: 'Battery Level', id: 2 },
      { label: 'Wearable Percentage', id: 3 },
      { label: 'Data Sync', id: 4 },
    ];
    const StageOptions = [
      { label: 'Screening', id: 1, value:"screening" },
      { label: 'Study Start - iPad Tasks', id: 2, value:"ipad_tasks"},
      { label: 'Study Start - Treatment', id: 3, value:"treatment" },
      { label: 'Study Maintenance', id: 4, value:"maintenance" },
      { label: 'Frequent Flyer', id: 5, value:"frequent_flyer" },
      { label: 'Paused', id: 6 , value:"paused"},
      { label: 'Study Completion', id: 7, value:"stim_completed" },
    ];
export default function RuleDialog ({props, label}) {
  const [open, setOpen] = useState(false);
  const [deviceType, setDeviceType] = useState('');
  const [ruleType, setRuleType] = useState('');
  const [stageType, setStageType] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentStudy, setCurrentStudy] = useState('DEMO01');
  const [studyStages, setStudyStages] = useState([]);

  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [ confirm, setConfirm ] = useState(false);

  
  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    setConfirm(false);
  }

  const handlePrint = () => {
    window.location.print();
  }

  const handleConfirm = () => {
    setConfirm(true);
  }

  const handleRuleChange =() => {
    let ruleRequestURL = "/api/v1/adherence_cfgs";
    let rule_id = deviceType.id;
    let study_id = ruleType.id;
    let study_stage = stageType.value;
    var data = {"study_id":study_id, 
    "rule_id": rule_id, 
    'registration_status':study_stage };
    axios.post(ruleRequestURL, data , config).then(response => {
      console.log('in post response', response);
      //console.log(deviceType, ruleType, stageType);
      setUpdating(true);
    }).catch(error => {
      console.log('error', error, "data ", data);
      setUpdating(false);
      if (error.response.status !== 404) {
        removeUserSession();
        window.location.reload();
      }
    });
    handleClose();
  }

  const getActiveStages = async ()=>{
    try {
      const summaryUrl = `/api/v1/study/${currentStudy}`;
      const response = await axios.get(summaryUrl, config).catch(e => {
        console.log('error');
        console.log(e);
        if (e.response.status !== 404) {
          removeUserSession();
          window.location.reload();
        }
      });
      let resObj = response.data.adherence_configurations;
      let objSet = new Set();
      for (const [key, value] of Object.entries(resObj)) {
        //console.log(key,value);
        objSet.add(value.stage);
      }
      let result = Array.from(objSet);
      setStudyStages(result);
    }
    catch(error) {
      console.log(error)
      console.log(error);
    }
  }
  
    return (
      <div>
        <Button onClick={handleClickOpen} color="secondary" variant ="text">
          {label}
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Create Adherence Rules</DialogTitle>
          <DialogContent>
            
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={DeviceOptions}
                sx={{ width: 300 }}
                
                onChange={(event, newValue) => {
                setDeviceType(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                }}
                renderInput={(params) => <TextField {...params} label="Ex: Watch" variant ="standard"/>}
              />
              <DialogContentText>
              <b>Device</b>
              </DialogContentText>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={RuleOptions}
                sx={{ width: 300 }}
                
                onChange={(event, newValue) => {
                setRuleType(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                }}
                renderInput={(params) => <TextField {...params} label="Ex: Watch Battery Low (3.75 v)" variant ="standard"/>}
              />
              <DialogContentText>
                <b>Adherence Rule</b>
              </DialogContentText>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={StageOptions}
                sx={{ width: 300 }}
                
                onChange={(event, newValue) => {
                setStageType(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                }}
                renderInput={(params) => <TextField {...params} label="Ex: Screening" variant ="standard"/>}
              />
              <DialogContentText>
                <b>Applicable Study Stage</b>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button 
                onClick={handleClose}>Cancel
              </Button>
              <Button 
                onClick={handleConfirm}>Create Rule
              </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={confirm} onClose={handleClose}>
          <DialogTitle>Rule Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to create this rule?
            </DialogContentText>
          </DialogContent>
            <DialogActions>
              <Button 
                onClick={handleClose}>Cancel
              </Button>
              <Button 
                onClick ={handleRuleChange}>Confirm
              </Button>
            </DialogActions> 
        </Dialog>
      </div>
    )
}

