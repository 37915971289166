import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Dialog, DialogTitle, DialogContentText, Button, TextField, Typography } from '@mui/material'
import { DialogContent, DialogActions } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { getToken, getRole, getPriv, removeUserSession } from './Utils/Common';
import RuleDialog from './components/RuleDialog';
import Autocomplete from '@mui/material/Autocomplete';
import HandleRejection from './components/RoleRejection';

function getDeviceType(typeNumber) {
  switch (typeNumber) {
    case 0:
      return "GammaSense";
    case 1:
      return "Actigraph";
    case 2:
      return "iPAD";
    default:
      return typeNumber;
  }
}

function AdRules() {
  const [rules, setRules] = useState([]);
  const [studies, setStudies] = useState([]);
  const [filter, setFilter] = useState(false);
  const [filterStr, setFilterStr] = useState('');
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [currentStudy, setCurrentStudy] = useState('Select Study');
  const [row, setRow] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [custSupport, setCustSupport] = useState(false);
  const [reload, setReload] = useState(0);
  const displayedRules = 20;
  const studiesSet = new Set();
  const token = getToken();
  let role = getRole();
  let privilege = getPriv();
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const handleStudyChange = () => {
    setOpen(true);
  }

  const handleFilter = () => {
    setFilter(true);
    getRules();
    setOpen(false);
    setReload(reload + 1);
  }

  function isCustSupp() {
    if (role === "2" && privilege === "1") {
      setCustSupport(true);
    }
    else {
      setCustSupport(false);
    }
  }

  const handleRuleDownload = () => {
    function convertToCSV(arr) {
      const array = [Object.keys(arr[0])].concat(arr);
      console.log(array);
      return array.map(it => {
        return Object.values(it).toString()
      }).join('\n')
    }

    let csvData = convertToCSV(rules);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const a = document.createElement('a')
    a.download = "Adherence List";
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  }

  const handleClose = () => {
    setOpenConfirm(false);
    setOpen(false);
  }

  const handleConfirm = (a) => {
    setRow(a);
    setOpenConfirm(true);
    //setActive(a,b,c);
  }
  useEffect(() => {
    getRules();
    isCustSupp();
  }, [filterStr, reload]);

  const handleRequest = (row) => {
    setActive(row[0], row[1], row[2]);
    console.log(row);
  }

  const setActive = async (id) => {
    try {
      //TODO: Get rule data from DB and parse
      const adCfgUrl = '/api/v1/adherence_cfg/' + id;
      const response = await axios.delete(adCfgUrl, config).catch(e => {
        console.log('error');
        console.log(e);
        if (e.response.status !== 404) {
          removeUserSession();
          window.location.reload();
        }
      });
      console.log(response);
      let adherenceRules = [];

      setRules(adherenceRules);
      setCount(adherenceRules.length);
    }
    catch (error) {
      console.log(error);
    }
    window.location.reload();
  }

  const getRules = async (next_page) => {
    try {
      if (!next_page) {
        next_page = 1;
      }
      else if (next_page >= 1) {
        next_page = next_page + 1
      }

      //TODO: Get rule data from DB and parse
      const adCfgUrl = '/api/v1/adherence_cfgs?page=' + next_page;
      console.log('adCfg:', adCfgUrl);
      const response = await axios.get(adCfgUrl, config).catch(e => {
        console.log('error');
        console.log(e);
        if (e.response.status !== 404) {
          removeUserSession();
          window.location.reload();
        }
      });
      console.log(response);
      var parseRule;
      let adherenceRules = [];
      let deviceTypeStr = "";
      for (let i = 0; i < response.data.adherence_config.length; i++) {
        deviceTypeStr = getDeviceType(response.data.adherence_config[i].device_type);
        parseRule = {
          'id': i,
          'study_rule_id': response.data.adherence_config[i].id,
          'Enable': "Enable",
          'category': response.data.adherence_config[i].category,
          'global_rule_id': response.data.adherence_config[i].rule_id,
          'dev_type': deviceTypeStr,
          'study_ID': response.data.adherence_config[i].study_id,
          'study_stage': response.data.adherence_config[i].registration_status,
          'item': response.data.adherence_config[i].description,
          'rule_type': response.data.adherence_config[i].name,
        };
        if (filter) {
          if (filterStr === "Reset Filter") {
            window.location.reload();
          }
          else if (response.data.adherence_config[i].study_id === filterStr) {
            adherenceRules.push(parseRule);
            studiesSet.add("Reset Filter");
          }
        }
        else {
          adherenceRules.push(parseRule);
          studiesSet.add(response.data.adherence_config[i].study_id);
        }
      }
      setStudies(Array.from(studiesSet));
      setRules(adherenceRules);
      setCount(adherenceRules.length);
      if (reload === 0) {
        setCurrentStudy("Select Study");
      }
      else {
        setCurrentStudy(adherenceRules[0].study_ID);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const columns = [
    {
      field: 'dev_type',
      headerName: 'Device',
      width: 120,
      sortable: true,
      editable: false,
    },
    {
      field: 'study_ID',
      headerName: 'Study ID',
      width: 160,
      sortable: true,
      editable: false,
    },
    {
      field: 'global_rule_id',
      headerName: 'Rule ID',
      width: 130,
      sortable: true,
      editable: false,
    },
    {
      field: 'item',
      headerName: 'Rule Details',
      width: 260,
      sortable: true,
      editable: false,
    },
    {
      field: 'study_stage',
      headerName: 'Study Stage',
      width: 200,
      sortable: true,
      editable: false,
    },
    {
      field: 'category',
      headerName: 'Rule Category',
      width: 200,
      sortable: true,
      editable: false,
    },
    {
      field: 'acknowledge_alert', // triggered note
      headerName: 'Action',
      width: 190,
      hide: (custSupport),
      sortable: false,
      editable: false,
      renderCell: (params) =>
        <div>
          <Button title="Disable Rule"
            color="secondary"
            onClick={() => handleConfirm(params.row.id, params.row.dev_type, params.row.item, params.row.active)}
          >
            <RemoveCircleIcon />
          </Button>
        </div>,
    },
  ];

  if (role !== "6") {
    return (
      <div style={{ width: "100%" }} overflow="visible">
        <Typography variant="h6" color="secondary">
          Adherence Rules:
        </Typography>
        <Button title='Select Study' color="secondary" variant='outlined' onClick={handleStudyChange}>
          {currentStudy}
        </Button>
        <Button color="secondary" variant="text" onClick={() => handleRuleDownload()}>
          Download Rules Report
        </Button>
        <div style={{ background: "white" }}>
          <DataGrid
            color="secondary"
            rows={rules}
            columns={columns}
            autoHeight={true}
            loading={rules.length === 0}
            rowHeight={38}
            rowCount={count}
            disableSelectionOnClick
            pagination
            onPaginationModelChange={(newPage) => {setPage(newPage.page); getRules(newPage.page);}}
            paginationModel={{ page: page, pageSize: displayedRules }}
          />
          {role === "0" || privilege === "0" ?
            <RuleDialog label="Create New Rule" />
            :
            <></>
          }
          <Dialog open={openConfirm} onClose={handleClose}>
            <DialogTitle>Updated Adherence Rules</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to remove rule {row}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}>Cancel
              </Button>
              <Button
                onClick={handleRequest}>Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Updated Study Rule Set</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Select a Study ID, when finished select confirm.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Autocomplete
                variant="standard"
                disablePortal
                id="combo-box-demo"
                options={studies}
                sx={{ width: 300 }}
                onInputChange={(event, newInputValue) => {
                  setFilterStr(newInputValue);
                }}
                renderInput={(params) => <TextField {...params} label="Study ID" variant='standard' />}
              />
              <Button onClick={handleClose}>
                Cancel
              </Button>
              <Button onClick={handleFilter}>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
  else {
    return (<HandleRejection open={true} />);
  }
};

export default AdRules;
