import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Typography,
    Grid,
    TextField,
    Box,
    ButtonGroup,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Snackbar,
    Dialog, 
    DialogTitle, 
    DialogContentText 
  } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { nameConstants } from './constants';
import Alert from '@mui/material/Alert';
import { getUser, getToken, getRole, getPriv, removeUserSession } from './Utils/Common';
import { DialogContent, DialogActions} from '@mui/material';
import HandleRejection from './components/RoleRejection';

let today = new Date();
let dd = String(today.getDate()).padStart(2, '0');
let mm = String(today.getMonth() + 1).padStart(2, '0');
let yyyy = today.getFullYear();
today = mm + '/' + dd + '/' + yyyy;

function AssignDevice() {
  //Form field states
  const user = getUser();
  const role = getRole();
  const [ subjectID, setSubjectID] = useState('');
  const [ zipCode, setZipCode] = useState('');
  const [ operatorInfo, setOperatorInfo ] = useState(user);
  const [ watchSernum, setWatchSernum] = useState('');
  const [ deviceInfoWatch, setDeviceInfoWatch ] = useState('');
  const [ operatorNotesWatch, setOperatorNotesWatch ] = useState('');
  const [ shipDateWatch, setShipDateWatch ] = useState(null);
  const [ startDateWatch, setStartDateWatch ] = useState(null);
  const [ reportDateWatch, setReportDateWatch ] = useState(today);
  const [ tabletSernum, setTabletSernum] = useState('');
  const [ deviceInfoTablet, setDeviceInfoTablet ] = useState('');
  const [ operatorNotesTablet, setOperatorNotesTablet ] = useState('');
  const [ shipDateTablet, setShipDateTablet ] = useState(null);
  const [ startDateTablet, setStartDateTablet ] = useState(null);
  const [ reportDateTablet, setReportDateTablet ] = useState(today);

  //Display states
  const [ openWatch, setOpenWatch ] = useState(false);
  const [ openTablet, setOpenTablet ] = useState(false);
  const [ isPrinting, setIsPrinting ] = useState(false);
  const [ uploadSuccess, setUploadSuccess ] = useState(false);
  const [ uploadFailure, setUploadFailure] = useState(false);

  //Error states
  const [ error, setError ] = useState(false);
  const [ errorText, setErrorText ] = useState("Error: Please fill out all required fields");
  const [ subjectIDError, setSubjectIDError ] = useState(false);
  const [ zipError, setZipError ] = useState(false);
  const [ zipErrorText, setZipErrorText ] = useState('Incorrect format');
  const [ operatorError, setOperatorError ] = useState(false);
  const [ deviceError, setDeviceError ] = useState(false);
  const [ watchSernumError, setWatchSernumError ] = useState(false);
  const [ watchShipDateError, setWatchShipDateError ] = useState(false);
  const [ watchStartDateError, setWatchStartDateError ] = useState(false);
  const [ watchReportDateError, setWatchReportDateError ] = useState(false);
  const [ tabletSernumError, setTabletSernumError ] = useState(false);
  const [ tabletShipDateError, setTabletShipDateError ] = useState(false);
  const [ tabletStartDateError, setTabletStartDateError ] = useState(false);
  const [ tabletReportDateError, setTabletReportDateError ] = useState(false);

  const [ confirm, setConfirm ] = useState(false);
  const [open, setOpen] = useState(false);
  
  const token = getToken();
  const config = {
    headers: { 'Content-type': 'application/json',
               'Authorization': `Bearer ${token}` }
  };
  const handleClickOpen = () => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
    setConfirm(false);
  }
  const handleConfirm = () => {
    setConfirm(true);
  }
  const handleOverRide = (props) => {
    if(window.confirm("Device of this type already assigned. Would you like to override this assignment?")){
      console.log(props);
      handleEndDevice(props);
    }
    else{
      handleClose();
    }
  }
  const handleResetSernum = () =>{
    setWatchSernum('');
  }
  const checkFields = () => {
    let validFields = true;
    
    if(!subjectID) {
      setSubjectIDError(true);
      validFields = false;
    }
    else {
      setSubjectIDError(false);
    }

    if(zipCode) {
      var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);
      if(!isValidZip) {
        setZipErrorText("Invalid zip code format")
        setZipError(true);
        validFields = false;
      }
      else {
        setZipError(false);
      }
    }
    else {
      setZipErrorText("Zip code required");
      setZipError(true);
      validFields = false;
    }

    if(!operatorInfo){
      setOperatorError(true);
      validFields = false;
    }

    if(!watchSernum && !tabletSernum) { //At least one device is required
      setDeviceError(true);
      validFields = false;
    }

    if(watchSernum  || deviceInfoWatch ) { //check watch fields
      if(!watchSernum) {
        setWatchSernumError(true);
        validFields = false;
      }
      else {
        setWatchSernumError(false);
      }

      if(!reportDateWatch) {
        setWatchReportDateError(true);
        validFields = false;
      }
      else {
        setWatchReportDateError(false);
      }
    }
    if(tabletSernum || deviceInfoTablet ) { //check tablet fields
      if(!tabletSernum) {
        setTabletSernumError(true);
        validFields = false;
      }
      else {
        setTabletSernumError(false);
      }
      if(!reportDateTablet) {
        setTabletReportDateError(true);
        validFields = false;
      }
      else {
        setTabletReportDateError(false);
      }
    }
    return validFields;
  }

  const resetForm = () => {
    console.log("resetting form")
    setUploadSuccess(true);
    setSubjectID('');
    setZipCode('');
    setWatchSernum('');
    setDeviceInfoWatch('');
    setOperatorNotesWatch('');
    setShipDateWatch(null);
    setStartDateWatch(null);
    setReportDateWatch(today);
    setTabletSernum('');
    setDeviceInfoTablet('');
    setOperatorNotesTablet('');
    setShipDateTablet(null);
    setStartDateTablet(null);
    setReportDateTablet(today);
    setOpenWatch(false);
    setOpenTablet(false);
    setUploadFailure(false);
    setError(false);
    setSubjectIDError(false);
    setZipError(false);
    setOperatorError(false);
    setDeviceError(false);
    setWatchSernumError(false);
    setWatchShipDateError(false);
    setWatchStartDateError(false);
    setWatchReportDateError(false);
    setTabletSernumError(false);
    setTabletShipDateError(false);
    setTabletStartDateError(false);
    setTabletReportDateError(false);
  }

  const handleEndDevice = (props) => {
    try{
    var url = '/api/v1/assignment/' + props;
    var data = {
        start_date:new Date().toISOString(),
        end_date: new Date().toISOString(),
      }
      console.log(data);
      axios.put(url, data , config).catch(e => {
        console.log(e)
        if (e.response.status !== 404) {
          removeUserSession();
          window.location.reload();
        }       
      }).then(handleSave);
    }
    catch{
        console.log('error');
        console.log(error);
        setUploadFailure(true);
          setErrorText(error);
          setError(true);
    };
  }


  const handleGetSerial = () => {
    console.log("in getSerial")
    var url = '/api/v1/patient/'+ subjectID +'/cpmonitor';
        axios.get(url , config).then(response => {
          console.log(response);
          setWatchSernum(response.data.serial);
        })
        .catch(error => {
          console.log('error');
          console.log(error);
          setErrorText("Something went wrong. Please ensure the participant has a valid actigraph assigned in centerpoint.");
          setError(true);
          if (error.response && error.response.status && error.response.status !== 404) {
            console.log('in this')
            removeUserSession();
            window.location.reload();
          } 
        });
  }

  const handleSave = () => {
    setUploadSuccess(false);
    setUploadFailure(false); 
    //First check for required fields    
    let formComplete = checkFields();
    if (formComplete) {
      let numAssignments = 1;
      if(watchSernum && tabletSernum) {
        numAssignments = 2;
      }
      if(watchSernum) {
        var url = '/api/v1/assignments';
        var data = { unit_id: watchSernum,
                     device_type: 1,
                     participant_id: subjectID,
                     zip_code: zipCode,
                     operator_id: operatorInfo, //operator info
                     information: deviceInfoWatch, //ticket number
                     comments: operatorNotesWatch, //operator comments/notes
                    }; 
                     console.log(data);
        axios.post(url, data , config).then(response => {
          console.log(response);
          setUploadSuccess(true);
        }).catch(error => {
          console.log('error');
          console.log(error);
          setUploadFailure(true);
          if (error.response.status === 401) {
            setErrorText("Check to ensure you are logged in.");
            setError(true);
            if(window.confirm("Do you want to login again?")){
              console.log('in handleLogout');
              setError(null);
              removeUserSession();
              window.location.reload();
            }
          }
          else {
            setErrorText("Something went wrong. Please try again later.");
            setError(true);
          }
        });
      }
      if(tabletSernum) {
        var url = '/api/v1/assignments';  
        var data = { unit_id: tabletSernum,
                     device_type: 2,
                     participant_id: subjectID,
                     zip_code: zipCode,
                     operator_id: operatorInfo, //operator info
                     information: deviceInfoTablet, //ticket number
                     comments: operatorNotesTablet,
                    }; //operator comments/notes
        axios.post(url, data , config).then(response => {
          console.log(response);
          setUploadSuccess(true);
        }).catch(error => {
          console.log('error');
          console.log(error)
          setUploadFailure(true);
          if (error.response.status === 401) {
            setErrorText("Check to ensure you are logged in.");
            setError(true);
            if(window.confirm("Do you want to login again?")){
              console.log('in handleLogout');
              setError(null);
              removeUserSession();
              window.location.reload();
            }
          }
          else if (error.response.status === 400) {
            let old_val = error.response.data.assignment_id;
            setError(false);
            setUploadFailure(false);
            handleOverRide(old_val);
          }
          else {
            setErrorText(`Error: ${error.response.data.error}`);
            setError(true);
            setUploadFailure(true);
          }
        });
      }
    }
    else {
      if(deviceError) {
        setErrorText("Error: At least one type of device assignment is required");
        setError(true);
      }
      else {
        setErrorText("Error: Please fill out all required fields");
        setError(true);
      }
      setError(true);
    }
    handleClose();
  }
  const handlePrint = () => {
    setOpenWatch(true);
    setOpenTablet(true);
    setIsPrinting(true);
  }

  useEffect(() => {
    if(isPrinting) {
      setTimeout(() => {  window.print() }, 500); //Allow dropdowns to render first
      setIsPrinting(false);
    }
  }, [isPrinting])
  
  useEffect(() => {
    console.log(watchSernum, tabletSernum)
  }, [watchSernum, tabletSernum])
  if((role ==="0" ) || (role ==="3")){
  return (
    <div style={{width: "100%"}}>
      <Typography variant="h6" justify="center">
        Assign Actigraph Watch and Tablet to {nameConstants.Patient}
      </Typography>
      <br/>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="subjectID"
            name="subjectID"
            label="Participant ID"
            value={subjectID}
            fullWidth
            required
            variant='standard'
            error={subjectIDError}
            onChange={(e) => setSubjectID(e.target.value)}
          />
        </Grid>
        {subjectIDError && (
          <Grid item>
            <Typography sx={{ color: 'red', fontSize: '10px' }}>{"Participant ID Required"}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            id="assignedTo"
            name="assignedTo"
            label="Zip Code"
            value={zipCode}
            fullWidth
            required
            variant='standard'
            error={zipError}
            onChange={(e) => setZipCode(e.target.value)}
          />
        </Grid>
        {zipError && (
          <Grid item>
            <Typography sx={{ color: 'red', fontSize: '10px' }}>{zipErrorText}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField 
            id="operatorInfo"
            name="operatorInfo"
            label="Operator Information"
            value={operatorInfo}
            fullWidth
            required
            variant='standard'
            error={operatorError}
            onChange={(e) => setOperatorInfo(e.target.value)}
          />
        </Grid>
        {operatorError && (
          <Grid item>
            <Typography sx={{ color: 'red', fontSize: '10px' }}>{"Operator ID Required"}</Typography>
          </Grid>
        )}
      </Grid>
      <br/> 
      <div>
        <Accordion expanded={openWatch} onChange={() => setOpenWatch(!openWatch)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Assign Actigraph</Typography>
          </AccordionSummary>
          <AccordionDetails>
          {watchSernum!==''?<Button 
              id="print"
              name="print"
              onClick={handleResetSernum}
            >
              Reset Serial
            </Button>:<Button 
              id="print"
              name="print"
              onClick={handleGetSerial}
            >
              Get Serial
            </Button>}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField 
                id="watchSernum"
                name="watchSernum"
                label="Actigraph Watch S/N"
                value={watchSernum}
                fullWidth
                required
                variant='standard'
                InputProps={{
                  readOnly: true,
                }}
                error={watchSernumError}
                />
              </Grid>
              {watchSernumError && (
                <Grid item>
                  <Typography sx={{ color: 'red', fontSize: '10px' }}>{"Actigraph Serial Number Required"}</Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField 
                  variant='standard'
                  id="actigraphInfo"
                  name="Ticket Number"
                  label="Ticket Number"
                  value={deviceInfoWatch}
                  fullWidth
                  onChange={(e) => setDeviceInfoWatch(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant='standard' 
                  id="operatorNotes"
                  name="operatorNotes"
                  label="Operator Comments/Notes"
                  value={operatorNotesWatch}
                  fullWidth
                  onChange={(e) => setOperatorNotesWatch(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Assignment Date *"
                    value={reportDateWatch}
                    required
                    error={watchReportDateError}
                    onChange={(newValue) => {
                      setReportDateWatch(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} variant='standard'/>}
                  />
                </LocalizationProvider>
              </Grid>
              {watchReportDateError && (
                <Grid item>
                  <Typography sx={{ color: 'red', fontSize: '10px' }}>{"Assignment Date Required"}</Typography>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion  expanded={openTablet} onChange={() => setOpenTablet(!openTablet)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>Assign Tablet</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField 
                  variant='standard'
                  id="tabletSernum"
                  name="tabletSernum"
                  label="Tablet S/N"
                  value={tabletSernum}
                  fullWidth
                  required
                  error={tabletSernumError}
                  onChange={(e) => setTabletSernum(e.target.value)}
                />
              </Grid>
              {tabletSernumError && (
                <Grid item>
                  <Typography sx={{ color: 'red', fontSize: '10px' }}>{"Tablet Serial Number Required"}</Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  variant='standard' 
                  id="tabletInfo"
                  name="TicketNumber"
                  label="Ticket Number"
                  value={deviceInfoTablet}
                  fullWidth
                  onChange={(e) => setDeviceInfoTablet(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant='standard' 
                  id="operatorNotes"
                  name="operatorNotes"
                  label="Operator Comments/Notes"
                  value={operatorNotesTablet}
                  fullWidth
                  onChange={(e) => setOperatorNotesTablet(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Assignment Date *"
                    value={reportDateTablet}
                    required
                    error={tabletReportDateError}
                    onChange={(newValue) => {
                      setReportDateTablet(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} variant='standard'/>}
                  />
                </LocalizationProvider>
              </Grid>
              {tabletReportDateError && (
                <Grid item>
                  <Typography sx={{ color: 'red', fontSize: '10px' }}>{"Assignment Date Required"}</Typography>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
      <br/>
      <Grid item xs={12}>
        <Box textAlign='center'>
          <ButtonGroup variant="text" color="secondary">
            <Button 
              id="save"
              name="save"
              onClick={handleConfirm}
            >
              Confirm & Save
            </Button>
          </ButtonGroup>
        </Box>
      <Snackbar open={uploadSuccess} autoHideDuration={6000} onClose={() => {setUploadSuccess(false)}} sx={{position:'static'}}>
        <Alert onClose={() => {setUploadSuccess(false)}} severity="success" sx={{ width: '100%' }}>
          Device Successfully Assigned
        </Alert>
      </Snackbar>
      <Snackbar open={uploadFailure} autoHideDuration={6000} onClose={() => {setUploadFailure(false)}} sx={{position:'static'}}>
        <Alert onClose={() => {setUploadFailure(false)}} severity="error" sx={{ width: '100%' }}>
          Could not assign device, please try again
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={6000} onClose={() => {setError(false)}} sx={{position:'static'}}>
        <Alert onClose={() => {setError(false)}} severity="error" sx={{ width: '100%' }}>
          {errorText}
        </Alert>
      </Snackbar>
      </Grid>
      <Dialog open={confirm} onClose={handleClose}>
          <DialogTitle>Assignment Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to Assign the device: {watchSernum || tabletSernum} to participant: {subjectID}?
            </DialogContentText>
          </DialogContent>
            <DialogActions>
              <Button 
                onClick={handleClose}>Cancel
              </Button>
              <Button 
                onClick ={handleSave}>Confirm
              </Button>
            </DialogActions> 
        </Dialog>
    </div>
    
  );}
  else
 {
    return(
        <HandleRejection open={true}/>
          );
 }
}
export default AssignDevice;
