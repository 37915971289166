import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import axios from 'axios';
import { LicenseInfo } from '@mui/x-license-pro';
import MyLoginZoidComponent from './components/login'
LicenseInfo.setLicenseKey(
  '0c427d11cb235e40c24c9435de9b072dT1JERVI6MjY1MDEsRVhQSVJZPTE2NTYzNDUyNDkwMDAsS0VZVkVSU0lPTj0x',
);

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

ReactDOM.render(<App {...window.xprops}/>, document.getElementById('root'));
