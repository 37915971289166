import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { getToken, getRole, removeUserSession } from './Utils/Common';
import { nameConstants } from './constants';
import HandleRejection from './components/RoleRejection';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, Box, FormControl, InputLabel, Select, MenuItem, Accordion, Button, Typography, AccordionSummary, TextField, Grid } from '@mui/material';

function Patients() {
  const role = getRole();
  const token = getToken();
  const [patients, setPatients] = useState([]);
  const [patientCount, setPatientCount] = useState(0);
  const [siteCount, setSiteCount] = useState(0);
  const [page, setPage] = useState(0);
  const [filterKey, setFilterKey] = useState("");
  const [filter, setFilter] = useState("");
  const [refresh, setRefresh] = useState(0);
  const dashColors = ["grey", "red", "orange"];

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  // get patients on init render
  useEffect(() => {
    getPatientList();
  }, [refresh]);

  const handleHasAlert = (arg1, arg2, arg3) => {
    if (arg1 === "alert" || arg2 === "alert" || arg3 === "alert") {
      return true;
    }
    else {
      return false;
    }
  }

  const getPatientList = async (next_page) => {
    try {
      if (!next_page) {
        next_page = 1;
      }
      else if (next_page >= 1) {
        next_page = next_page + 1;
      }

      let studyFilter = "";
      let siteFilter = "";
      let subjectIdFilter = "";
      let fmtFilterKey = filterKey.trim();

      if (filter === 0) {
        subjectIdFilter = encodeURIComponent(fmtFilterKey);
      }
      else if (filter === 1) {
        siteFilter = encodeURIComponent(fmtFilterKey);
      }
      else if (filter === 2) {
        studyFilter = encodeURIComponent(fmtFilterKey);
      }

      const summaryUrl = '/api/v1/summary';
      const summaryRes = await axios.get(summaryUrl, config).catch(e => {
        console.log('error');
        console.log(e);
        if (e.response.status !== 404) {
          removeUserSession();
          window.location.reload();
        }
      });
      const patientsUrl = '/api/v1/patients?sort=-study_site_id&study=' +
        studyFilter + "&site=" + siteFilter + "&subject=" + subjectIdFilter + "&page=" + next_page;
      const response = await axios.get(patientsUrl, config).catch(e => {
        console.log('error');
        console.log(e);
        if (e.response.status !== 404) {
          removeUserSession();
          window.location.reload();
        }
      });

      let unprocessedPatients = response.data.participants;
      console.log(response);
      let processedPatients = [];
      var parsedPatient;
      let watchALert = '';
      let gs120Alert = '';
      let ipadAlert = '';
      for (let i = 0; i < unprocessedPatients.length; i++) {
        watchALert = '';
        gs120Alert = '';
        ipadAlert = '';
        if (unprocessedPatients[i].adherence_status.actigraph && unprocessedPatients[i].adherence_status.actigraph.includes('|')) {
          watchALert = unprocessedPatients[i].adherence_status.actigraph.split('|')[0];
        } if (unprocessedPatients[i].adherence_status.gs120 && unprocessedPatients[i].adherence_status.gs120.includes('|')) {
          gs120Alert = unprocessedPatients[i].adherence_status.gs120.split('|')[0];
        } if (unprocessedPatients[i].adherence_status.iPad && unprocessedPatients[i].adherence_status.iPad.includes('|')) {
          ipadAlert = unprocessedPatients[i].adherence_status.iPad.split('|')[0];
        }
        parsedPatient = {
          'id': unprocessedPatients[i].subject_id,
          'start_date': unprocessedPatients[i].start_date,
          'end_date': unprocessedPatients[i].end_date,
          'status': unprocessedPatients[i].registration_status,
          'site_name': unprocessedPatients[i].study_site.site_id,
          'watch_alert': watchALert ? watchALert : unprocessedPatients[i].adherence_status.actigraph,
          'ipad_alert': ipadAlert ? ipadAlert : unprocessedPatients[i].adherence_status.iPad,
          'gs120_alert': gs120Alert ? gs120Alert : unprocessedPatients[i].adherence_status.gs120
        }
        processedPatients.push(parsedPatient);
      }
      setSiteCount(summaryRes.data.site_count);
      setPatientCount(response.data.count);
      setPatients(processedPatients);
    }
    catch (error) {
      console.log("error");

      if (error.toString().includes('Network Error')) { //Prevent crashing for 500 error
        setPatients([]);
        setPatientCount(0);
      }
      else if (error.response.status !== 404) {
        console.log(error)
      }
    }
  }

  function downloadObjectAsJson(exportObj, exportName) {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  const handleRequestDownload = (params) => {
    let url = `api/v1/patient/${params.row.id}`;
    axios.get(url, config).catch(e => {
      console.log('error');
      console.log(e);
      if (e.response.status !== 404) {
        removeUserSession();
        window.location.reload();
      }
    }).then(response => {
      downloadObjectAsJson(response, `Participant Summary:${params.row.id}`);
    });
  }

  const handleFilterRequest = () => {
    setPatients([]);
    setRefresh(refresh + 1);
  }

  const clearFilter = () => {
    setFilterKey("");
    setFilter("");
    handleFilterRequest();
  }

  const patientColumns = [
    {
      field: 'id',
      headerName: nameConstants.Patient + ' ID',
      width: 170,
      sortable: true,
      editable: false,
      renderCell: (params) => {
        let fontColor = dashColors[0];
        if (handleHasAlert(params.row.ipad_alert, params.row.gs120_alert, params.row.watch_alert)) {
          fontColor = dashColors[1];
        }
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'site_name',
      headerName: 'Site Name',
      width: 170,
      sortable: true,
      editable: false,
      renderCell: (params) => {
        let fontColor = dashColors[0];
        if (handleHasAlert(params.row.ipad_alert, params.row.gs120_alert, params.row.watch_alert)) {
          fontColor = dashColors[1];
        }
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Study Stage',
      width: 170,
      sortable: true,
      editable: false,
      renderCell: (params) => {
        let fontColor = dashColors[0];
        if (handleHasAlert(params.row.ipad_alert, params.row.gs120_alert, params.row.watch_alert)) {
          fontColor = dashColors[1];
        }
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'watch_alert',
      headerName: 'Actigraph Watch',
      width: 200,
      sortable: true,
      editable: false,
      renderCell: (params) => {
        let fontColor = dashColors[0];
        if (handleHasAlert(params.row.ipad_alert, params.row.gs120_alert, params.row.watch_alert)) {
          fontColor = dashColors[1];
        }
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'ipad_alert',
      headerName: 'iPad',
      width: 150,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        let fontColor = dashColors[0];
        if (handleHasAlert(params.row.ipad_alert, params.row.gs120_alert, params.row.watch_alert)) {
          fontColor = dashColors[1];
        }
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'gs120_alert',
      headerName: 'GS120',
      width: 150,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        let fontColor = dashColors[0];
        if (handleHasAlert(params.row.ipad_alert, params.row.gs120_alert, params.row.watch_alert)) {
          fontColor = dashColors[1];
        }
        return (
          <div style={{ color: fontColor }}> {params.value} </div>
        );
      }
    },
    {
      field: 'acknowledge_alert', // triggered note
      headerName: 'Action',
      width: 190,
      sortable: false,
      editable: false,
      renderCell: (params) =>
        <div>
          <Button
            title="View Patient Details"
            href={'/' + nameConstants.Patient + '/' + params.row.id}
            color="secondary"
          >
            <ManageAccountsIcon />
          </Button>
          <Button
            color='secondary'
            title='Download Participant Summary'
            disabled={role === "2"}
            onClick={() => handleRequestDownload(params)}
          >
            <DownloadIcon />
          </Button>
        </div>,
    },
  ];

  const downloadFile = (participantIds) => {
    participantIds = participantIds.toString();
    participantIds = participantIds.replace(/,/g, "\n");
    let fileName = `TGR_Patients`;

    const blob = new Blob([participantIds], { type: 'text/csv' });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  }

  const fetchTGRData = async (page) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/TGRconfigs?page=` + page;
    return await axios.get(url, config).catch(e => {
      console.log('error');
      console.log(e);
      if (e.response.status !== 404) {
        removeUserSession();
        window.location.reload();
      }
    });
  }

  const handleGetTGRList = async () => {
    let tgrPatients = [];

    fetchTGRData(1).then(response => {
      console.log(response)
      let tgrSettings = response.data.settings;
      for (let i = 0; i < tgrSettings.length; i++) {
        tgrPatients.push(tgrSettings[i].data.participant_id)
      }
      let pageCount = response.data.last_page;
      if (pageCount > 1) {
        for (let i = 2; i <= pageCount; i++) {
          fetchTGRData(i).then(response => {
            let tgrSettings = response.data.settings;
            for (let j = 0; j < tgrSettings.length; j++) {
              tgrPatients.push(tgrSettings[j].data.participant_id)
            }
            if (i === pageCount) {
              downloadFile(tgrPatients);
            }
          })
        }
      }
      else {
        downloadFile(tgrPatients);
      }
    }).catch(e => {
      console.log(e)
    });
  }

  console.log(patients);
  if (role === "0" || role === "2" || role === "3") {
    return (
      <div>
        <Grid container>
          <Grid item>
            <font size="5" color="blue">Total Sites: {siteCount} </font>
          </Grid>
          <div style={{ flexGrow: 1 }} />
          <Grid item>
            {patientCount > 0 && <Button onClick={() => handleGetTGRList()} color="secondary" variant="text" disabled={role !== "0" && role !== "3"}>T+GR Patients</Button>}
          </Grid>
        </Grid>
        {nameConstants.Patient + "s"} (Total: {patientCount} ):
        <br />
        <br />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography color="secondary"> Search Table</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ maxWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filter}
                  label="Filter"
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <MenuItem value={0}>Participant ID</MenuItem>
                  <MenuItem value={1}>Site</MenuItem>
                  <MenuItem value={2}>Study</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <br />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Filter Key"
              type="note"
              fullWidth
              variant="standard"
              value={filterKey}
              onChange={(e) => setFilterKey(e.target.value)}
            />
            <Button color="secondary" variant="text" onClick={handleFilterRequest}>
              Search
            </Button>
            {filterKey &&
              <>
                <Button color="secondary" variant="text" onClick={clearFilter}>
                  Clear Filter
                </Button>
              </>
            }
          </AccordionDetails>
        </Accordion>
        {patientCount > 0 && (
          <div style={{ background: "white" }}>
            <DataGrid
              color="secondary"
              rows={patients}
              columns={patientColumns}
              autoPageSize={true}
              autoHeight={true}
              loading={patients.length === 0}
              rowHeight={38}
              rowCount={patientCount}
              disableSelectionOnClick
              pagination
              paginationMode="server"
              onPaginationModelChange={(newPage) => { setPage(newPage.page); getPatientList(newPage.page); }}
              paginationModel={{ page: page, pageSize: 20 }}
            />
          </div>
        )}
        {(filterKey && patientCount === 0) &&
          <>
            <div style={{ color: "red" }}>Your search returned 0 results</div>
          </>
        }
        <br />
        <br />
      </div>
    );
  }
  else {
    return (<HandleRejection open={true} />);
  }
}
export default Patients;
