import { DialogContent, DialogActions, FormControl, Autocomplete, Dialog, DialogTitle, DialogContentText ,Button, TextField } from '@mui/material';
import React, {useState} from 'react'
import axios from 'axios'
import { getToken, removeUserSession } from '../Utils/Common';

const token = getToken();
const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

export default function EnableStatus ({props, label, stage}) {
    const [open, setOpen] = useState(false);
    const [deviceType, setDeviceType] = useState(null);
    const [status, setStatus] = useState("");
    const [statusOp, setStatOp] = useState(new Set());

    const handleClickOpen = () => {
      const study = props.split("-");
      console.log(study[0]);
        setOpen(true);
        getActiveStages(study[0]);
    }
    const handleClose = () => {
        setOpen(false);
    }

//This function is used to get the study stages
//TODO we should adapt this in the future to return both all studies and study specific results. 
    const getActiveStages = async (study)=>{
      try {
        const summaryUrl = "/api/v1/study/" + study;
        console.log (summaryUrl)
        const response = await axios.get(summaryUrl, config).catch(e => {
          console.log('error');
          console.log(e);
          if (e.response.status !== 404) {
            removeUserSession();
            window.location.reload();
          }
        });
        console.log(response.data)
        let resObj = response.data.study_attributes.valid_stages;
        console.log(resObj)
        let result = Array.from(resObj);
        setStatOp(result);
      }
      catch(error) {
        console.log(error)
        setStatOp([]);
      }
    }

    const handleActiveRequest = () => {
    console.log(props);
    let notificationurl = "/api/v1/patient/" + props
    let data = {status: status}
    axios.put(notificationurl, data, config).then(response => {
        console.log('in put response');
        console.log(response);
      }).catch(e => {
        console.log('error');
        console.log(e);
        if (e.response.status !== 404) {
          removeUserSession();
          window.location.reload();
        }
      });
      handleClose();
    }

    return (
      <div>
        <Button onClick={handleClickOpen} color="secondary" variant ="text">
          {label}
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Change Status</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please select the participant status
            </DialogContentText>
              <FormControl>
              <Autocomplete
                disablePortal
                id="combo-box-stages"
                options={statusOp}
                sx={{ width: 400 ,height: 300}}
                label="Study Stages" 
                onInputChange={(event, newInputValue) => {
                  setStatus(newInputValue);
                  console.log(event);
                  console.log(newInputValue);
                  }}
                renderInput={(params) => <TextField {...params} />}
                  />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleActiveRequest}>Set Status</Button>
            </DialogActions>
        </Dialog>
      </div>
    )
}

