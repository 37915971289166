import Jwt_Decode from "jwt-decode";

export default function VerifyToken() {
    if (localStorage.getItem("token")) {
        const jwt_Token_decoded = Jwt_Decode(localStorage.getItem("token"));
        console.log(jwt_Token_decoded.exp * 1000);
        console.log(Date.now());
        if (jwt_Token_decoded.exp * 1000 < Date.now()) {
            localStorage.clear();
        }
    }
}