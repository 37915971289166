import React, {useState, useEffect } from 'react';
import axios from 'axios';
import { getRole, getToken, removeUserSession} from './Utils/Common';
import { DataGrid } from '@mui/x-data-grid';
import HandleRejection from './components/RoleRejection';

function Events(props) {

  const { params } = props.match;
  const role = getRole();
  const unit_id = params.unit_id;
  const event_type = params.event_type;
  const start_date = params.start_date;
  const end_date = params.end_date;
  const assignment = params.assignment;
  const token = getToken();
  const [ events, setEvents ] = useState([]);
  const [ count, setCount ] = useState(0);
  const [ page, setPage ] = useState(0);

  const config = {
    headers: { Authorization: `Bearer ${token}` }
   };

  // get events on init render
  useEffect(() => {
    getEventsList();
  }, [] );

  function processEvents(unprocessedEvents) {
    console.log(unprocessedEvents);
    let processedEvents = [];
    var event, event_message;
    for (let i=0; i < unprocessedEvents.length; i++) {
      event_message = unprocessedEvents[i].message_data;
      if (unprocessedEvents[i].error_data !== null) {
        console.log(unprocessedEvents[i].error_data )
        console.log(unprocessedEvents[i].id )
      }
      if(event_message && event_message.Settings && !event_message.Settings.Fuel_G){ //Settings
        event = {'id': unprocessedEvents[i].id, 'event_date': unprocessedEvents[i].event_date, 'posted_date': unprocessedEvents[i].posted_date, 'message_type': unprocessedEvents[i].message_type,
        'treatment_id': unprocessedEvents[i].treatment_id, 'event_type': unprocessedEvents[i].event_type, 'vbat': event_message.Settings.battery, 
        'volume': event_message.Settings.treatment.ASTIM, 'intensity': event_message.Settings.treatment.VSTIM, 'frequency': event_message.Settings.treatment.freq, 'onValue': event_message.Settings.treatment.on};
        processedEvents.push(event);
      }
      else { //Stats or any other type
        event = {'id': unprocessedEvents[i].id, 'event_date': unprocessedEvents[i].event_date, 'posted_date': unprocessedEvents[i].posted_date, 'message_type': unprocessedEvents[i].message_type,
        'treatment_id': unprocessedEvents[i].treatment_id, 'event_type': unprocessedEvents[i].event_type || "", 'vbat': "", 'volume': "", 'intensity': "", 'frequency': "", 'onValue': ""};
        processedEvents.push(event);
      }
    }
    console.log(processedEvents);
    setEvents(processedEvents);
  }

  const getEventsList=async(next_page)=>{
    try {
        if(!next_page) {
          next_page = 1;
        }
        else if (next_page >= 1) {
          next_page = next_page + 1
        }
        var url;
        if(start_date !== 'none' && end_date !== 'none') { 
          url = '/api/v1/' + event_type +'/' + unit_id + '/events?start_date=' + start_date + '&end_date=' + end_date + '&page=' + next_page;
        }
        else {
          url = '/api/v1/' + event_type +'/' + unit_id + '/events?page=' + next_page;
        }
        console.log('eventurl:',url);
        const response=await axios.get(url, config).catch(error => {
          if (error.response.status !== 404) {
            removeUserSession();
            props.history.push('/login');
          }
        });
        console.log(response)
        if (response.status === 404 || response.data.count === 0){
          setEvents([]);
          setCount(0);
        } else {
          processEvents(response.data.events);
          setCount(response.data.count);
        }
    } catch(error) {
      console.log(error)
      console.log(error.response);
      setEvents([]);
      setCount(0);
    }
  }

  function getMessageType(id){
    var type = '';
    switch(id){
      case 0:
        type = 'particle';
        break;
      case 1:
        type = 'settings';
        break;
      case 2:
        type = 'stats';
        break;
      case 3:
        type = 'treatment';
        break;
      default:
        type = 'unknown';
    }
    return type;
  }

  const eventColumns = [
    { field: 'event_date', headerName: 'Event Date', width: 200 },
    {
      field: 'posted_date',
      headerName: 'Posted Date',
      width: 200,
      sortable: false,
      editable: false,
    },
    {
      field: 'message_type',
      headerName: 'Message Type',
      width: 145,
      sortable: false,
      editable: false,
      valueFormatter: (params) => {
        const valueFormatted = getMessageType(params.value);
        return `${valueFormatted}`;
      }
    },
    {
      field: 'treatment_id',
      headerName: 'Treatment ID',
      width: 135,
      sortable: false,
      editable: false,
    },
    {
      field: 'event_type',
      headerName: 'Event Type',
      width: 125,
      sortable: false,
      editable: false,
    },
    {
      field: 'vbat',
      headerName: 'vbat',
      width: 130,
      sortable: false,
      editable: false,
    },
    {
      field: 'volume',
      headerName: 'volume',
      width: 200,
      sortable: false,
      editable: false,
    },
    {
      field: 'intensity',
      headerName: 'intensity',
      width: 200,
      sortable: false,
      editable: false,
    },
    {
      field: 'frequency',
      headerName: 'frequency',
      width: 200,
      sortable: false,
      editable: false,
    },
    {
      field: 'onValue',
      headerName: 'onValue',
      width: 200,
      sortable: false,
      editable: false,
    },
  ];

  console.log(page)

  if(role !== "6") {
    return (
      <div style={{width: "100%"}}>
        Events for {event_type} {unit_id} {assignment !== 'none' && ('on device ' + assignment)} - Total Events: {count}
        {count > 0 && (
          <div style={{background:"white"}}>
            <DataGrid
              color="secondary"
              rows={events}
              columns={eventColumns}
              autoHeight={true}
              loading={events.length === 0}
              rowHeight={38}
              rowCount={count}
              checkboxSelection
              disableSelectionOnClick
              pagination
              paginationMode="server"
              onPaginationModelChange={(newPage) => {setPage(newPage.page); getEventsList(newPage.page);} }
              paginationModel={{page:page, pageSize:20}}
            />
          </div>
        )} 
      </div>
    );
  }
  else {
    return(
      <HandleRejection open={true}/>
    );
  }
}

export default Events;
