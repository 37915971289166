import React from 'react';
import {
  Typography,
  Grid,
  TextField,
  Box,
  ButtonGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const handleReload = () => {
  window.location.reload()
}

const StubReport = ({props}) => {
    console.log(props);
    const operatorID = props.operator_id;
    const randomCode = props.rcode ||  "N/A";
    const subjectID = props.subject_id;
    const zipCode = props.zip_code;
    const operatorInfo = props.operator_info;
    const watchSernum = props.watch_sernum;
    const deviceInfoWatch = props.device_info_watch;
    const reportDate = props.device_date;
    const endDate = props.end_date;
    const tabletSernum = props.ipad_sernum;
    const deviceInfoTablet = props.device_info_ipad;
    const deviceType = props.device_type;
    const tgrSessionID = props.tgr_session_id;
    const protocolID = props.protocol_id;
    
    return (
      <div style={{width: "100%"}}>
        <Typography variant="h6" justify="center">
        Device Provision Report
        </Typography>
        <br/>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="subjectID"
              name="subjectID"
              label="Participant ID"
              value={subjectID}
              fullWidth
              required
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="assignedTo"
              name="assignedTo"
              label="Zip Code"
              value={zipCode}
              fullWidth
              required
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="OpInfo"
              name="OpInfo"
              label="Operator Information"
              value={operatorID}
              fullWidth
              required
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="rcode"
              name="rcode"
              label="Random Code"
              value={randomCode}
              fullWidth
              required
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
        <br/> 
        <div>
        <Accordion expanded={(deviceType ==="GS120")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>Assign GS120</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField 
                  id="watchSernum"
                  name="watchSernum"
                  label="Sensory Stimulation GS120 S/N"
                  value={watchSernum}
                  fullWidth
                  required
                  InputProps={{
                    readOnly: true,
                  }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="rcode"
                    name="rcode"
                    label="Randomization Code"
                    value={randomCode}
                    fullWidth
                    required
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField 
                    id="operatorNotes"
                    name="operatorNotes"
                    label="Ticket Number"
                    value={operatorInfo}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                <TextField 
                    id="assignDate"
                    name="assignDate"
                    label="Assignment Date"
                    value={reportDate}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                <TextField 
                    id="endDate"
                    name="endDate"
                    label="Prescription End Date"
                    value={endDate}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField 
                      id="tgrSessionID"
                      name="tgrSessionID"
                      label="T+GR Session ID"
                      value={tgrSessionID}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                  />
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        id="tgrProtocolID"
                        name="tgrProtocolID"
                        label="T+GR Protocol ID"
                        value={protocolID}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                    />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={(deviceType ==="Actigraph")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>Assign Actigraph</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField 
                  id="watchSernum"
                  name="watchSernum"
                  label="Actigraph Watch S/N"
                  value={watchSernum}
                  fullWidth
                  required
                  InputProps={{
                    readOnly: true,
                  }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField 
                    id="actigraphInfo"
                    name="actigraphInfo"
                    label="Actigraph Information"
                    value={deviceInfoWatch}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField 
                    id="operatorNotes"
                    name="operatorNotes"
                    label="Ticket Number"
                    value={operatorInfo}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                <TextField 
                    id="assignDate"
                    name="assignDate"
                    label="Assignment Date"
                    value={reportDate}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                <TextField 
                    id="endDate"
                    name="endDate"
                    label="End Date"
                    value={endDate}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion  expanded={(deviceType ==="iPad")} >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <Typography>Assign Tablet</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField 
                    id="tabletSernum"
                    name="tabletSernum"
                    label="Tablet S/N"
                    value={tabletSernum}
                    fullWidth
                    required
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField 
                    id="tabletInfo"
                    name="tabletInfo"
                    label="Tablet Information"
                    value={deviceInfoTablet}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField 
                    id="operatorNotes"
                    name="operatorNotes"
                    label="Ticket Number"
                    value={operatorInfo}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                <TextField 
                    id="assignDate"
                    name="assignDate"
                    label="Assignment Date"
                    value={reportDate}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                <TextField 
                    id="endDate"
                    name="endDate"
                    label="End Date"
                    value={endDate}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
        <br/>
        <Grid item xs={12}>
          <Box textAlign='center'>
            <ButtonGroup variant="text" color="secondary" onClick={handleReload}>
              {/* <Button 
                id="print"
                name="print"
                onClick={handlePrint}
              >
                Print Confirmation
              </Button> */}
              <Button 
                id="save"
                name="save"
              >
                Return to Patient Details
              </Button>
            </ButtonGroup>
          </Box>
        </Grid>
      </div>
    );
}
export default StubReport;