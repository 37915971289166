import React , {useState} from 'react';
import { uploadFile } from 'react-s3';
import { Alert, Grid, TextField, Typography } from '@mui/material';
import axios from 'axios';

const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_ACCESS_KEY
}

const UploadToS3WithReactS3 = () => {
    const [deviceID, setDeviceID] = useState("");
    const [participantID, setParticipantID] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [location, setFilePath] = useState(null);
    const [uploadError, setUploadError] = useState(null);
    const [newFile, setNewFile] = useState(null);
    const [uploadFlag, setUploadFlag] = useState(false);
    let current_date = Date();
    const COG_DARK = "#007898";
//filename format: ParticipantID+DeviceID+TimeStamp.ext
    //substring is used to capture minute data as seconds differ in name between upload & filetime. 
    const filename = participantID + "+" +deviceID + "+" +current_date + ".png";
    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
        let new_file = new File([selectedFile],filename) 
        setNewFile(new_file);
        console.log(current_date)
    }
    
    //function to report file info to API when upload is successful
    const handleAPI = async (props) => {
    const url = "/api/v1/device/" + deviceID + "/uploads";
    const data = {
        filename,
        "subject_id":participantID,
        "location": props.location
    }
    console.log("*DATAFLAG*",data)
    axios.post(url,data).then(response => {
            console.log('in post response');
            console.log(response)
        }).catch(error => {
            console.log(error);
            setUploadError("Something went wrong. Please try again later.");
        });
    }
    const handleUpload = async (file) => {
        uploadFile(file, config)
            .then(data => {
                console.log(data.location);
                setFilePath(data.location);
                handleAPI(data);
                setUploadFlag(true);
                setUploadError("Uploaded Successfully")
            })
            .catch(err => console.error(err))
    }

    return (
        <div>
            <Typography  variant="body1"  sx={{ color: COG_DARK }}><b>Quick File Upload</b></Typography>
            
            <Grid item xs={12}>
                <TextField
                    id="DeviceID"
                    name="DeviceID"
                    label="Device ID"
                    fullWidth
                    value={deviceID}
                    onChange={ (e) => setDeviceID(e.target.value)}
                    required="true"    
                /> 
            </Grid> 
            <Grid item xs={12}>
                <TextField
                    id="SubjectId"
                    name="SubjectId"
                    label="Participant ID"
                    fullWidth
                    value={participantID}
                    onChange={ (e) => setParticipantID(e.target.value)}
                    required="true"
                /> 
            </Grid>
            <br/>
            <input type="file" onChange={handleFileInput}/>
            <button onClick={() => handleUpload(newFile)}> Upload to S3</button>  
            
            {uploadFlag && <Alert children={uploadError} />}  
        </div>
    )
}

export default UploadToS3WithReactS3;